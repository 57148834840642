import {
  Checkbox, Form as AntForm, Input, Radio,
} from 'antd';
import AddressInput from 'components/AddressInput';
import FormItem from 'components/FormItem';
import React from 'react';

function MainForm({ type, merchantDetail, setMerchantDetail }) {
  const handleSetMerchantDetail = (fieldName, value) => {
    setMerchantDetail((current) => ({ ...current, [fieldName]: value }));
  };

  const isPartner = type === 'Partner';

  const selectAfter = (fieldName) => (
    <Radio.Group
      value={merchantDetail[fieldName]}
      onChange={(e) => handleSetMerchantDetail(fieldName, e.target.value)}
    >
      <Radio.Button value="$">
        $
      </Radio.Button>
      <Radio.Button value="%">%</Radio.Button>
    </Radio.Group>
  );
  const PARTNER_FORM = {
    detail: [
      {
        id: 1,
        value: merchantDetail.name,
        title: 'Name',
        onChange: (e) => handleSetMerchantDetail('name', e?.target?.value),
        idTitle: 'name',
        placeholder: 'Merchant’s name',
        required: true,
        active: true,
      },
      {
        id: 2,
        value: merchantDetail.email,
        title: 'Email',
        onChange: (e) => handleSetMerchantDetail('email', e?.target?.value),
        idTitle: 'email',
        placeholder: 'e.g. example@example.com',
        required: false,
        active: isPartner,
        errorMessage: 'email format is not correct',
      },
      {
        id: 3,
        value: merchantDetail.phone,
        title: 'Phone number',
        onChange: (e) => handleSetMerchantDetail('phone', e?.target?.value),
        idTitle: 'phone',
        placeholder: 'Phone number',
        required: true,
        active: true,
      },
    ],
    fees: [
      ...(isPartner
        ? [
          {
            id: 1,
            value: merchantDetail.deliveryCommission,
            title: 'Delivery commission',
            onChange: (e) => handleSetMerchantDetail('deliveryCommission', e?.target?.value),
            idTitle: 'deliveryCommission',
            placeholder: 'e.g. 15',
            required: false,
            active: isPartner,
            selectAfter,
          },
          {
            id: 2,
            value: merchantDetail.pickupCommission,
            title: 'Pickup commission',
            onChange: (e) => handleSetMerchantDetail('pickupCommission', e?.target?.value),
            idTitle: 'pickupCommission',
            placeholder: 'e.g. 15',
            required: false,
            active: isPartner,
            selectAfter,
          },
        ]
        : []),
      {
        id: 3,
        value: merchantDetail.markup,
        title: 'Markup',
        onChange: (e) => handleSetMerchantDetail('markup', e?.target?.value),
        idTitle: 'markup',
        placeholder: 'e.g. 15',
        required: false,
        active: true,
        selectAfter,
      },
    ],
    features: [
      {
        id: 1,
        name: 'gift',
        checked: merchantDetail.gift,
        onChange: (e) => handleSetMerchantDetail('gift', e),
        label: 'Send as a gift',
        required: false,
        active: true,
      },
      {
        id: 2,
        name: 'schedule',
        checked: merchantDetail.schedule,
        onChange: (e) => handleSetMerchantDetail('schedule', e),
        label: 'Scheduling ahead',
        required: false,
        active: true,
      },
    ],
  };

  const getNumActiveFeatures = () => PARTNER_FORM.features.reduce((total, curr) => total + +curr.active, 0);

  return (
    <>
      <div className="box">
        {PARTNER_FORM.detail.map((box) => {
          if (!box.active) return null;
          return (
            <AntForm.Item label={box.title} className="my-4">
              <Input
                type="text"
                placeholder={box.placeholder}
                value={box.value}
                onChange={box.onChange}
                id={box.idTitle}
              />
            </AntForm.Item>
          );
        })}
        <FormItem label="Address">
          <AddressInput
            value={{
              text: merchantDetail.address,
              lat: merchantDetail.location?.lat,
              lng: merchantDetail.location?.lng,
            }}
            onChange={
              (addr) => {
                handleSetMerchantDetail('address', addr.text);
                handleSetMerchantDetail('location', { lat: addr.lat, lng: addr.lng });
              }
            }
          />
        </FormItem>
      </div>

      <div className="box">
        <span className="box-header">Fee structure</span>
        {PARTNER_FORM.fees.map((box) => {
          if (!box.active) return null;
          return (
            <AntForm.Item label={box.title} className="my-4">
              <Input
                type="text"
                placeholder={box.placeholder}
                value={box.value}
                onChange={box.onChange}
                id={box.idTitle}
                addonAfter={selectAfter(`${box.idTitle}Type`)}
              />
            </AntForm.Item>
          );
        })}
      </div>
      {getNumActiveFeatures() > 0 && (
        <div className="box checkBox">
          <span className="box-header">Available features</span>
          {PARTNER_FORM.features.map((box) => {
            if (!box.active) return null;
            return (
              <Checkbox
                key={box.id}
                id={box.id}
                onChange={() => {
                  box.onChange(!box.checked);
                }}
                checked={box.checked}
              >
                {box.label}
              </Checkbox>
            );
          })}
        </div>
      )}
    </>
  );
}

export default MainForm;
