import React from 'react';

function GlutenFreeIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.15" x="1.15771" y="1.31982" width="13.3333" height="13.3333" rx="4" fill="black" fill-opacity="0.45"/>
      <path d="M9.36487 10.8801V5.12012H12.8849V6.20812H10.4529V7.45612H12.4049V8.54412H10.4529V10.8801H9.36487Z" fill="black" fill-opacity="0.45"/>
      <path d="M5.83517 11C5.47251 11 5.12717 10.936 4.79917 10.808C4.47117 10.6773 4.18051 10.4853 3.92717 10.232C3.67651 9.97867 3.47784 9.66533 3.33117 9.292C3.18717 8.91867 3.11517 8.488 3.11517 8C3.11517 7.36267 3.23651 6.82133 3.47917 6.376C3.72451 5.928 4.05251 5.58667 4.46317 5.352C4.87651 5.11733 5.33384 5 5.83517 5C6.57651 5 7.15917 5.172 7.58317 5.516C8.00717 5.86 8.29117 6.34667 8.43517 6.976L7.32317 7.136C7.21917 6.8 7.04851 6.532 6.81117 6.332C6.57651 6.12933 6.27517 6.02667 5.90717 6.024C5.54184 6.01867 5.23784 6.09733 4.99517 6.26C4.75251 6.42267 4.56984 6.65333 4.44717 6.952C4.32717 7.25067 4.26717 7.6 4.26717 8C4.26717 8.4 4.32717 8.74667 4.44717 9.04C4.56984 9.33333 4.75251 9.56133 4.99517 9.724C5.23784 9.88667 5.54184 9.97067 5.90717 9.976C6.15517 9.98133 6.37917 9.93867 6.57917 9.848C6.78184 9.75733 6.95251 9.61467 7.09117 9.42C7.22984 9.22533 7.32851 8.976 7.38717 8.672H6.47517V7.832H8.54717C8.55251 7.86667 8.55651 7.928 8.55917 8.016C8.56184 8.104 8.56317 8.15467 8.56317 8.168C8.56317 8.72 8.45117 9.20933 8.22717 9.636C8.00317 10.0627 7.68717 10.3973 7.27917 10.64C6.87117 10.88 6.38984 11 5.83517 11Z" fill="black" fill-opacity="0.45"/>
    </svg>
  );
}
export default GlutenFreeIcon;
