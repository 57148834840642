import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button, Col, Input, InputNumber, Row,
} from 'antd';
import React from 'react';

function Option({
  data, changeOption, addOption, removeOption, index,
}) {
  return (
    <Row gutter={8} align="middle">
      <Col span={7}>
        <InputNumber
          value={Number(data.price)}
          type="number"
          prefix="$"
          style={{ width: '100%' }}
          onChange={(price) => changeOption({ index, option: { ...data, price } })}
        />
      </Col>
      <Col span={11}>
        <Input
          value={data.type}
          style={{ width: '100%' }}
          onChange={(e) => changeOption({ index, option: { ...data, type: e.target.value } })}
        />
      </Col>
      <Col span={6}>
        {index === 0 && (
          <Button
            type="text"
            icon={<PlusOutlined />}
            onClick={() => addOption()}
          >
            Add
          </Button>
        )}
        {index > 0 && (
          <Button
            type="text"
            icon={<MinusOutlined />}
            style={{ marginLeft: 8 }}
            onClick={() => removeOption(index)}
          />
        )}
      </Col>

    </Row>
  );
}

export default Option;
