import {
  Flex, Form as AntForm, Input, Select,
} from 'antd';
import useIsMobile from 'hooks/useIsMobile';
import React from 'react';

function OmniMerchantForm({ merchantDetail, setMerchantDetail }) {
  const isMobile = useIsMobile();
  const OMNI_TYPE = [
    {
      value: 'Toast',
      label: 'Toast',
    },
    {
      value: 'Olo',
      label: 'Olo',
    },
    {
      value: 'Square',
      label: 'Square',
    },
  ];

  const handleSetMerchantDetail = (fieldName, value) => {
    const data = { ...merchantDetail };
    data[fieldName] = value;
    setMerchantDetail(data);
  };
  return (
    <Flex vertical className="box">
      <AntForm.Item label="Platform">
        <Select
          value={merchantDetail.integration}
          onChange={(value) => handleSetMerchantDetail('integration', value)}
          options={OMNI_TYPE}
          style={{
            width: 'fit-content',
            minWidth: isMobile ? 200 : 350,
          }}
          placeholder="Select Omni Type"
        />
      </AntForm.Item>
      <AntForm.Item label="URL">
        <Input
          type="text"
          placeholder="Merchant URL (ex: https://test-merchant.com)"
          value={merchantDetail.url}
          onChange={(e) => handleSetMerchantDetail('url', e.target.value)}
        />
      </AntForm.Item>
    </Flex>
  );
}
export default OmniMerchantForm;
