import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  Button, Col, Flex, Radio, Row, Select, Tabs,
} from 'antd';
import { getOrders } from 'api/orders';
import Layout from 'components/Layout';
import { AdminContext, NewOrdersContext } from 'constants/contexts';
import React, {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import withGuard from 'components/withGuard';
import { PERMISSIONS } from 'constants/permissions';
import { useHasAnyOfPermissions } from 'hooks/useHasPermission';
import * as orderStatus from '../../constants/status';
import { FILTERS, TABS as ORDERS_TAB, TYPE_OPTIONS } from './constants';
import Table from './Table';
import LocationSelectInput from './LocationSelectInput';
import SourceSelectInput from './OrderSourceSelectInput';
import PaymentTypeSelectInput from './PaymentTypeSelectInput';
import { isOrderStatus } from 'utils/orders';

function Orders() {
  const [search, setSearch] = useState('');
  const resetFetchRef = useRef(false);
  const [activeTab, setActiveTab] = useState('Today');
  const [dataVersion, setDataVersion] = useState(1);
  const [activeFilter, setActiveFilter] = useState('In progress');
  const isAdmin = useHasAnyOfPermissions([PERMISSIONS.ADMIN_ACCESS]);
  const [filters, setFilters] = useState({
    type: null,
    parents: [],
    sources: [],
    paymentTypes: [],
  });
  const { organizationId } = useContext(AdminContext);

  const history = useHistory();
  const handleRefetch = useCallback(() => setDataVersion((v) => v + 1), []);
  const handleCreateOrder = useCallback(
    () => history.push('/console/orders/call-in/create'),
    [],
  );
  const handleResetFetch = useCallback(() => {
    resetFetchRef.current = true;
  }, []);
  const { newOrderIds, newScheduledOrderIds } = useContext(NewOrdersContext);
  const TABS = useMemo(() => ORDERS_TAB({
    hasNewOrder: activeTab !== 'Today' && !!newOrderIds.length,
    hasNewScheduledOrder: !!newScheduledOrderIds.filter((el) => !newOrderIds.includes(el))?.length,
    isAdmin,
  }), [newOrderIds, newScheduledOrderIds, isAdmin]);
  useEffect(() => {
    handleRefetch();
  }, [handleRefetch, newOrderIds]);

  const queryStatesIn = useMemo(() => {
    if (activeTab === 'Today' && activeFilter === 'New') {
      return `${orderStatus.NEW}`;
    }
    return `${TABS.find((t) => t.key === activeTab)?.state}`;
  }, [activeFilter, activeTab]);

  const queryStatesNotIn = useMemo(() => {
    if (activeTab === 'Today' && activeFilter === 'In progress') {
      return [
        orderStatus.COMPLETED,
        orderStatus.CANCELLED_BY_RESTAURANT,
        orderStatus.REJECTED_BY_RESTAURANT,
        orderStatus.DELIVERED,
        orderStatus.STRANDED,
        orderStatus.PAYMENT_SCHEDULED,
        !isAdmin && orderStatus.NEEDS_APPROVAL,
      ].filter(isOrderStatus).join(',');
    }
    return `${TABS.find((t) => t.key === activeTab)?.state__not_in}`;
  }, [activeFilter, activeTab, isAdmin]);

  const fetchOrders = useCallback(
    (pagination) => {
      const page = resetFetchRef.current ? 1 : pagination.current;
      const queries = {
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        search,
        ordering: '-is_new,-timestamp',
        state__in: queryStatesIn,
        state__not_in: queryStatesNotIn,
        order_types: filters.type,
        page,
        page_size: pagination.pageSize,
        parent__in: filters.parents.join(','),
        source__in: filters.sources.join(','),
        payment__in: filters.paymentTypes.join(','),
      };
      resetFetchRef.current = false;

      if (TABS.find((t) => t.key === activeTab)?.only_scheduled) {
        queries.only_scheduled = 1;
      }
      if (TABS.find((t) => t.key === activeTab)?.only_today) {
        queries.only_today = 1;
      }

      return getOrders(queries)
        .then(({ data: { data: { results, count } } }) => ({
          data: results,
          total: count,
          page,
        }))
        .finally(() => {});
    },
    [
      search,
      activeTab,
      dataVersion,
      queryStatesIn,
      queryStatesNotIn,
      organizationId,
      filters,
    ],
  );

  const handleSearchChange = (e) => {
    handleResetFetch();
    setSearch(e.target.value);
  };

  const handleChangeTab = (value) => {
    handleResetFetch();
    setActiveFilter(FILTERS[0]);
    setActiveTab(value);
  };

  const handleChangeFilter = ({ target: { value } }, _) => {
    handleResetFetch();
    setActiveFilter(value);
  };

  const handleChangeFilterFn = (name) => (value) => setFilters((c) => ({ ...c, [name]: value }));

  return (
    <Layout header={(
      <Layout.Header
        title="Orders"
        onSearch={handleSearchChange}
        extra={[
          isAdmin && (
            <Button
              key="add-order"
              icon={<PlusOutlined />}
              onClick={handleCreateOrder}
              type="default"
            >
              Create order
            </Button>
          ),
          <Button
            key="refresh"
            icon={<ReloadOutlined />}
            onClick={handleRefetch}
            type="link"
          >
            Refresh
          </Button>,
        ].filter(Boolean)}
      />
    )}
    >
      <div>
        <Tabs items={TABS} defaultActiveKey={TABS[0].key} onChange={handleChangeTab} />
        <Flex gap={16} wrap="wrap" align="start" className="mb-4">
          <Radio.Group
            value={activeFilter}
            options={FILTERS}
            onChange={handleChangeFilter}
            optionType="button"
            disabled={activeTab !== 'Today'}
            style={{ whiteSpace: 'nowrap' }}
          />
          <Select
            value={filters.type}
            onChange={handleChangeFilterFn('type')}
            options={TYPE_OPTIONS}
          />
          <LocationSelectInput value={filters.parents} onChange={handleChangeFilterFn('parents')} />
          <SourceSelectInput value={filters.sources} onChange={handleChangeFilterFn('sources')} />
          {
            isAdmin && <PaymentTypeSelectInput value={filters.paymentTypes} onChange={handleChangeFilterFn('paymentTypes')} />
          }

        </Flex>
        <Table
          fetchOrders={fetchOrders}
          refetch={handleRefetch}

        />
      </div>
    </Layout>
  );
}

export default withGuard(Orders, [PERMISSIONS.ORDERS], { redirect: true });
