import { Skeleton, Space, Spin } from 'antd';
import React, { useEffect, useContext } from 'react';
import Modifier from './Modifier';
import { modifierContext } from '../ModifierProvider';

function Modifiers({ errorVersion }) {
  const { visibleModifiers, unSatisfiedModifiers, loading } = useContext(modifierContext);

  // TODO: use event
  useEffect(() => {
    if (unSatisfiedModifiers.length === 0) {
      return;
    }
    document
      .getElementById(getModifierId(unSatisfiedModifiers[0]))
      ?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
  }, [errorVersion]);

  function getModifierId(modifier) {
    return `app-modifier-${modifier.id}`;
  }

  if (loading) {
    return <Skeleton paragraph={{ rows: 4 }} />;
  }

  if (visibleModifiers.length === 0) {
    return null;
  }
  return (
    <>
      {visibleModifiers.map((m, index) => (
        <Modifier modifier={m} />
      ))}
    </>
  );
}

export default Modifiers;
