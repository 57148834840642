/* eslint-disable camelcase */

import { isOmni, isOmniPartner } from 'containers/OrdersTab/utils';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Select,
  Input,
  Button,
  Form as AntForm,
  Space,
  Row,
  Col,
  App,
} from 'antd';
import { MERCHANT_TYPE_LABEL, MERCHANT_TYPE } from 'constants/index';
import withSkeleton from 'components/withSkeleton';
import { getHttpErrorMessage } from 'services/http';
import Typography from 'antd/es/typography/Typography';
import { InfoCircleFilled } from '@ant-design/icons';
import { captureException } from 'utils/errors';
import PaymentInfo from './PaymentInfo';
import FormLayout from '../../../components/FormLayout';
import AddressInput from '../../../components/AddressInput';
import MultiTypeAmountInput from '../../../components/MultiTypeAmountInput';
import SwitchInput from '../../../components/SwitchInput';
import FormItem from '../../../components/FormItem';
import TaxesInput from './TaxesInput';
import FileInput from './FileInput';
import TagsInput from './TagsInput';
import ParentInput from './ParentInput';
import SchedulingDaysInput from './SchedulingDaysInput';

function Form({
  data, merchantId, onSubmit,
}) {
  const [form] = AntForm.useForm();
  const [loading, setLoading] = useState(false);
  const { message } = App.useApp();
  useEffect(() => {
    if (!data) {
      return;
    }
    form.setFieldsValue(deserialize(data));
  }, [data, form]);

  const isEdit = () => {
    if (data?.id && location.pathname.includes('edit')) {
      return true;
    }
    return false;
  };

  const history = useHistory();
  const location = useLocation();

  const handleSubmit = async () => {
    setLoading(true);
    onSubmit(serialize(form.getFieldsValue()))
      .then((data) => {
        message.success('Merchant updated successfully!');
        if (data) {
          form.setFieldsValue(deserialize(data));
        }
      }).catch((e) => {
        captureException(e);
        message.error(getHttpErrorMessage(e));
      }).finally(() => {
        setLoading(false);
      });
  };

  const PARTNER_TYPE = [
    {
      value: false,
      label: MERCHANT_TYPE_LABEL[MERCHANT_TYPE.NON_PARTNER_MERCHANT],
    },
    {
      value: true,
      label: MERCHANT_TYPE_LABEL[MERCHANT_TYPE.PARTNER_MERCHANT],
    },
  ];

  return (
    <FormLayout>
      <AntForm
        form={form}
        layout="vertical"
        labelCol={3}
        wrapperCol={3}
        onFinishFailed={() => {
          message.error('Please fix errors first!');
        }}
        onFinish={handleSubmit}
      >
        <FormLayout.Section title="General info" divider={false}>
          <FormItem
            label="Type"
            name="is_partner"
          >
            <Select
              options={PARTNER_TYPE}
              placeholder="Select Type"
              disabled
            />
          </FormItem>
          <FormItem
            label="Location"
            name="parent"
          >
            <ParentInput selectedParent={data?.parent} />
          </FormItem>

          <FormItem label="Address" name="address">
            <AddressInput />
          </FormItem>
          <FormItem label="Merchant's name" name="name">
            <Input type="text" />
          </FormItem>
          <FormItem label="Description" name="description">
            <Input.TextArea type="text" />
          </FormItem>
          <FormItem label="Email" name="email">
            <Input type="email" placeholder="e.g. example@example.com" />
          </FormItem>
          <FormItem label="Phone number" name="phone">
            <Input type="tel" />
          </FormItem>
          <FormItem label="Liquor license" name="liquor_license">
            <Input type="text" />
          </FormItem>
          <FormItem label="Tags" name="tags_ids" fullWidth>
            <TagsInput selectedTags={data?.tags || []} />
          </FormItem>
          <FormItem label="Taxes" name="tax_ids" fullWidth>
            <TaxesInput selectedTaxes={data?.tax || []} />
          </FormItem>
        </FormLayout.Section>

        <FormLayout.Section title="Pictures">
          <Typography.Paragraph type="secondary" className="pb-4">
            <InfoCircleFilled />
            {' '}
            You can upload the merchant's logo and cover here
          </Typography.Paragraph>
          <Space wrap>
            <FormItem noStyle name="cover">
              <FileInput />
            </FormItem>
            <FormItem noStyle name="logo">
              <FileInput />
            </FormItem>
          </Space>
        </FormLayout.Section>
        <FormLayout.Section title="Payment methods">
          <Space direction="vertical">
            <SwitchInput label="Debit/Credit (Stripe)" disabled checked />
            {/* Not working due to the changes on API*/}
            {/*<FormItem noStyle boolean name="cash_payment">*/}
            {/*  <SwitchInput label="Cash at the door" />*/}
            {/*</FormItem>*/}
            {/*<FormItem noStyle boolean name="debit_payment">*/}
            {/*  <SwitchInput label="Debit at the door" />*/}
            {/*</FormItem>*/}
          </Space>
        </FormLayout.Section>

        {merchantId && (
          <FormLayout.Section title="Payout info">
            <PaymentInfo merchantId={merchantId} />
          </FormLayout.Section>
        )}
        <FormLayout.Section title="Fee structure">
          <Typography.Paragraph type="secondary">
            If the field was null we add the parent fees default
          </Typography.Paragraph>
          <FormItem
            hidden={isOmni(data?.migration_driver) || !data?.is_partner}
            label="Delivery commission"
            name="application_delivery_fee"
          >
            <MultiTypeAmountInput />
          </FormItem>
          <FormItem
            hidden={isOmni(data?.migration_driver) || !data?.is_partner}
            label="Pickup commission"
            name="application_pickup_fee"
          >
            <MultiTypeAmountInput />
          </FormItem>
          <FormItem label="Markup" name="markup">
            <MultiTypeAmountInput />
          </FormItem>
        </FormLayout.Section>

        <FormLayout.Section title="Additional Details" style={{ marginBottom: 18 }}>
          <Row gutter={40}>
            {!data?.is_catering && (
              <Col xs={24} lg={12}>
                <FormItem name="has_send_gift" boolean fullWidth>
                  <SwitchInput label="Send as a gift" description="Enable the “Send as a gift” feature to allow your customers to order meals from your restaurant and have them delivered as thoughtful gifts to their friends." />
                </FormItem>
              </Col>
            )}
            <Col xs={24} lg={12}>
              <FormItem name="is_gfo" boolean fullWidth hidden={!data?.is_partner || isOmniPartner(data)}>
                <SwitchInput label="Google food ordering" description="Google Food Ordering enables customers to order and pay for food from restaurants directly through Google Search results and Google Maps, streamlining the dining experience." />
              </FormItem>
            </Col>
            {/* <Col xs={24} lg={12}>
              <FormItem name="auto_accept" boolean fullWidth>
                <SwitchInput label="Auto accept" description="The Auto Accept feature automatically accepts incoming orders after a specified time, streamlining the kitchen's workflow by eliminating manual order acceptance." />
              </FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <FormItem name="auto_approval" boolean fullWidth>
                <SwitchInput label="Auto approve/reject" description="The Auto Approve/Reject feature automatically decides on pending orders after a set time, streamlining order management by reducing manual oversight." />
              </FormItem>
            </Col> */}
            <Col xs={24} lg={12}>
              <Space direction="vertical">
                <FormItem name="has_scheduling_order" boolean fullWidth>
                  <SwitchInput
                    label="Scheduled ordering"
                    disabled={data?.is_catering}
                    description={(
                      <>
                        The Schedule Ordering feature enables restaurants to manage and organize food orders efficiently by allowing customers to place orders for a specific future time.
                        <AntForm.Item noStyle shouldUpdate>
                          {() => (
                            <FormItem
                              className="mt-2"
                              label="How many days customers can pre order"
                              name="scheduling_available_to"
                              style={{ width: '100%' }}
                            >
                              <SchedulingDaysInput
                                isCatering={data?.is_catering}
                                disabled={!form.getFieldValue('has_scheduling_order')}
                              />
                            </FormItem>
                          )}
                        </AntForm.Item>
                      </>
                    )}
                  />
                </FormItem>
              </Space>
            </Col>
            {!data?.is_catering && (
              <Col xs={24} lg={12}>
                <FormItem name="should_send_aggregated_orders_to_merchant" boolean fullWidth>
                  <SwitchInput label="Order aggregation" description="Streamlining order management and enhancing efficiency by consolidates orders from various platforms like UberEats and DoorDash." />
                </FormItem>
              </Col>
            )}
          </Row>
        </FormLayout.Section>

        <FormLayout.Footer>
          <Space>
            <Button onClick={() => history.goBack()}>
              Back
            </Button>
            <Button
              // onClick={handleSubmit}
              loading={loading}
              type="primary"
              htmlType="submit"
            >
              {isEdit() ? 'Save' : 'Create merchant'}
            </Button>
          </Space>
        </FormLayout.Footer>
      </AntForm>
    </FormLayout>
  );
}
function deserialize({
  application_delivery_fee_fixed,
  application_delivery_fee_percent,
  application_pickup_fee_percent,
  application_pickup_fee_fixed,
  tax,
  tags,
  config: {
    // auto_accept_order,
    // auto_approve_reject,
    debit_payment_enabled,
    cash_payment_enabled,
  },
  payment_config,
  extra_commissions_invoice_threshold_in_cents,
  extra_commissions_percentage,
  parent,
  address,
  location,
  ...data
} = {}) {
  return {
    ...data,
    application_delivery_fee: {
      amount: +application_delivery_fee_fixed || +application_delivery_fee_percent,
      type: +application_delivery_fee_fixed ? '$' : '%',
    },
    application_pickup_fee: {
      amount: +application_pickup_fee_fixed || +application_pickup_fee_percent,
      type: +application_pickup_fee_fixed ? '$' : '%',
    },
    markup: {
      amount: +data.markup || +data.markup_fixed,
      type: +data.markup ? '%' : '$',
    },
    tax_ids: tax.map((t) => t.id),
    tags_ids: tags.map((t) => t.id),
    // auto_accept: Boolean(auto_accept_order),
    // auto_approval: Boolean(auto_approve_reject),
    debit_payment: Boolean(debit_payment_enabled),
    cash_payment: Boolean(cash_payment_enabled),
    debit_credit_payment: payment_config?.payment_driver === 'stripe',
    extra_commission_amount: +extra_commissions_invoice_threshold_in_cents / 100,
    extra_commission_percent: (+extra_commissions_percentage || 0) * 100,
    parent: parent?.id,
    address: { text: address, lat: location?.lat, lng: location?.lng },
  };
}

function serialize({
  application_delivery_fee: applicationDeliveryFee,
  application_pickup_fee: applicationPickupFee,
  extra_commission_amount,
  extra_commission_percent,
  markup,
  parent,
  address,
  ...data
}) {
  const serializedData = { ...data };
  if (typeof parent !== 'number') {
    serializedData.parent = parent.value;
  }
  if (applicationDeliveryFee.type === '%') {
    serializedData.application_delivery_fee_percent = applicationDeliveryFee.amount;
    serializedData.application_delivery_fee_fixed = 0;
    serializedData.should_use_fixed_delivery_fee = false;
  } else {
    serializedData.application_delivery_fee_fixed = applicationDeliveryFee.amount;
    serializedData.application_delivery_fee_percent = 0;
    serializedData.should_use_fixed_delivery_fee = true;
  }

  if (applicationPickupFee.type === '%') {
    serializedData.application_pickup_fee_percent = applicationPickupFee.amount;
    serializedData.application_pickup_fee_fixed = 0;
    serializedData.should_use_fixed_pickup_fee = false;
  } else {
    serializedData.application_pickup_fee_fixed = applicationPickupFee.amount;
    serializedData.application_pickup_fee_percent = 0;
    serializedData.should_use_fixed_pickup_fee = true;
  }

  if (markup.type === '%') {
    serializedData.markup = markup.amount;
    serializedData.markup_fixed = 0;
    serializedData.should_use_fixed_markup = false;
  } else {
    serializedData.markup_fixed = markup.amount;
    serializedData.markup = 0;
    serializedData.should_use_fixed_markup = true;
  }
  serializedData.extra_commissions_invoice_threshold_in_cents = (extra_commission_amount || 0) * 100;
  serializedData.extra_commissions_percentage = (extra_commission_percent || 0) / 100;

  // TODO: we should send the complete address when backend is ready
  serializedData.address = address?.text;
  serializedData.location = { lat: address?.lat, lng: address?.lng };

  return serializedData;
}

export default withSkeleton(Form, FormLayout.Skeleton);
