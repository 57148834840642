import React, { useContext } from 'react';
import { Checkbox, Flex } from 'antd';
import { modifierContext } from '../ModifierProvider';
import { getModifierMaxSelections, isAutoApplied } from '../utils';
import OptionRow from '../OptionRow';
import './style.css';
import QuantityInput from '../../../../../../components/QuantityInput';

function MultiSelectModifier({ modifier, disabled }) {
  const { options } = modifier;
  const maxSelections = getModifierMaxSelections(modifier);
  const {
    isSelected, handleChange, getTotalQty, getSelectedOption,
  } =
    useContext(modifierContext);

  function renderOption(option) {
    const { supports_multiple_quantity: supportMultipleQty } = option;
    const selected = isSelected({ option, modifier });
    const qty = getSelectedOption({ option, modifier })?.qty ?? 0;
    const otherQty = getTotalQty(modifier) - qty;
    const maxQty = Math.min(maxSelections - otherQty, option.max_qty || 100);

    if (isAutoApplied(modifier) && !selected) {
      handleChange({ modifier, option, value: { ...option, qty: 1 } });
    }
    if (supportMultipleQty) {
      console.log({ option });
      return (
        <Flex gap={8}>
          <QuantityInput
            key={option.id}
            style={{ marginRight: 15 }}
            value={qty}
            min={0}
            max={maxQty}
            className="py-2"
            onChange={(qty) => {
              if (qty === 0) {
                return handleChange({ option, modifier, value: undefined });
              }
              handleChange({ option, modifier, value: { ...option, qty } });
            }}
            disabled={disabled}
          />
          <OptionRow option={option} />
        </Flex>

      );
    }
    return (

      <Checkbox
        key={option.id}
        style={{ display: 'flex', width: '100%' }}
        className="omc-select-row"
        checked={isSelected({ option, modifier })}
        onChange={() => {
          if (selected) {
            return handleChange({ option, modifier, value: undefined });
          }
          handleChange({
            option,
            modifier,
            value: { ...option, qty: 1, modifierTitle: modifier?.title },
          });
        }}
        disabled={
          disabled || (maxQty === 0 && !isSelected({ modifier, option }))
        }
      >
        <OptionRow option={option} />
      </Checkbox>
    );
  }

  return (
    <Flex vertical>
      {options.map(renderOption)}
    </Flex>
  );
}

export default MultiSelectModifier;
