import { DeleteOutlined, ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons';
import {
  Alert, Button, Col, Flex, Row, Space, Spin, Typography,
} from 'antd';
import EmptyComponent from 'components/Empty';
import BankIcon from 'components/icons/BankIcons';
import StripePaymentModal from 'components/StripePaymentModal';
import useIsMobile from 'hooks/useIsMobile';
import React, { useState } from 'react';
import { captureException } from 'utils/errors';
import { deletePaymentMethod } from './requests';

function PaymentMethods({ cards, reFetch, loading: cardLoading }) {
  const isMobile = useIsMobile();
  const [loading, setLoading] = useState(false);
  const [isStripePaymentModalOpen, setIsStripePaymentModalOpen] =
    useState(false);

  const onRemovePaymentMethod = (cardId) => {
    setLoading(true);
    deletePaymentMethod(cardId)
      .then(reFetch)
      .catch((e) => captureException(e))
      .finally(() => setLoading(false));
  };

  if (cardLoading) {
    return <Spin style={{ marginTop: '10%', marginLeft: '50%' }} />;
  }
  if (!cards.length) {
    return (
      <>
        <EmptyComponent
          title=""
          description="Please proceed to add a payment card to ensure uninterrupted access to subscription services."
          icon={<ExclamationCircleFilled style={{ fontSize: 58, color: '#DAEADE' }} />}
          actionButtonText="Add payment method"
          onClick={() => setIsStripePaymentModalOpen(true)}
        />
        <StripePaymentModal
          reloadCards={reFetch}
          onClose={() => {
            setIsStripePaymentModalOpen(false);
          }}
          open={isStripePaymentModalOpen}
        />
      </>
    );
  }
  return (
    <Flex vertical gap={24}>
      <Alert
        message="Securely pay your bills using your preferred payment card via Stripe's robust gateway, integrated directly into our admin panel for an efficient and hassle-free transaction experience."
      />

      <Flex vertical gap={8}>
        {cards?.map(({ card, id }, index) => (
          <Row key={index} gutter={16}>
            <Col xs={20} sm={12} md={8} xl={5} xxl={3}>
              <Space style={{
                border: '1px solid #D9D9D9', borderRadius: 4, padding: 8, width: '100%',
              }}
              >
                <BankIcon
                  type={card.brand}
                  style={{ marginRight: '12px', display: 'flex' }}
                />
                <Typography.Text style={{ textTransform: 'capitalize' }}>
                  {card.brand}
                  {' '}
                  ••••
                  {card.last4}
                </Typography.Text>
              </Space>
            </Col>
            <Col xs={2} sm={4} className="align-self-center">
              <Button
                type="text"
                icon={<DeleteOutlined />}
                loading={loading}
                onClick={() => onRemovePaymentMethod(id)}
              >
                {!isMobile && 'Remove'}
              </Button>
            </Col>
          </Row>
        ))}
        <Row gutter={16}>
          <Col xs={20} sm={12} md={8} xl={5} xxl={3}>
            <Button
              icon={<PlusOutlined />}
              className="w-100"
              disabled={loading}
              onClick={() => setIsStripePaymentModalOpen(true)}
            >
              Add
            </Button>
          </Col>
        </Row>
      </Flex>

      <StripePaymentModal
        reloadCards={() => reFetch(true)}
        onClose={() => {
          setIsStripePaymentModalOpen(false);
        }}
        open={isStripePaymentModalOpen}
      />
    </Flex>
  );
}

export default PaymentMethods;
