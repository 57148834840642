import {
  getItemValue,
  isOmniPartner,
  isSelfDelivery,
} from 'containers/OrdersTab/utils';
import useIsDesktop from 'hooks/useIsDesktop';
import { AdminContext } from 'constants/contexts';
import React, {
  useContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  estimateOrder,
  isRideServiceAvailable,
  updateOrderState,
  approveOrder,
  getOrderDetailByOrderId,
} from 'api/orders';
import moment from 'moment';
import {
  Tooltip,
  message,
  Alert,
  Modal,
  Tag,
  Typography,
  Space,
  Divider,
  List,
  Descriptions,
  Radio,
  Input,
  Popconfirm,
  Skeleton,
  Button,
  Flex,
  App,
  Form,
} from 'antd';
import http, { getHttpErrorMessage } from 'services/http';
import { PaymentTypes } from 'containers/CreateOrder/constants';
import JumpLink from 'components/JumpLink';
import {
  CalendarOutlined,
  CreditCardOutlined,
  InfoCircleOutlined,
  MailOutlined,
  MessageOutlined,
  PhoneOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { MIGRATION_DRIVER } from 'constants/migrationTypes';
import { captureException } from 'utils/errors';
import FormItem from 'components/FormItem';
import { getOrderType } from 'utils/order';
import { PERMISSIONS } from 'constants/permissions';
import withGuard from 'components/withGuard';
import { useHasAnyOfPermissions } from 'hooks/useHasPermission';
import { checkExistDispatcher, createDispatcherJob } from '../OrdersTab/requests';
import TimeDialogue from '../OrdersTab/TimeDialogue';
import { RIDE_STATUS } from '../../constants';
import { formatAmount, getData } from '../../utils';
import * as orderStatus from '../../constants/status';

function OrderModal({
  orderId,
  data,
  handleOrderUpdate,
  onClose,
}) {
  const [orderDetail, setOrderDetail] = useState({});
  const history = useHistory();
  const isDesktop = useIsDesktop();
  const order = useMemo(
    () => ({
      ...data,
      ...(+orderDetail.id === +orderId ? orderDetail : {}),
    }),
    [data, orderDetail, orderId],
  );
  const [timeEST, setTimeEST] = useState('');
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [timeConfirmLoading, setTimeConfirmLoading] = useState(false);
  const [orderTime, setOrderTime] = useState(order.estimate);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [readyLoading, setReadyLoading] = useState(false);
  const [deliverLoading, setDeliverLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [selectedEstimateOption, setSelectedEstimateOption] = useState();
  const [estimatedPickupTs, setEstimatedPickupTs] = useState(null);
  const [canRequestDispatcher, setCanRequestDispatcher] = useState(false);
  const isAdmin = useHasAnyOfPermissions([PERMISSIONS.ADMIN_ACCESS]);
  const { organizationId } = useContext(AdminContext);
  const isDelivery = order.order_type === 2;
  const isScheduled = Boolean(order.scheduled_for);
  const isSelfDeliveryOrder = isSelfDelivery(order);
  const isSelfDeliveryOrOmniPartner =
    isSelfDeliveryOrder || isOmniPartner(order);
  const [messageApi, contextHolder] = message.useMessage();
  const open = Boolean(orderId);

  useEffect(() => {
    const prevValue = document.body.style.overflow;
    if (open) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = prevValue;
    };
  }, [open]);

  const handleEditOrder = (id) => {
    history.push(`/console/orders/${id}/edit`);
  };

  const estimateOptions = useMemo(
    () => [
      { id: 0, title: '15 minutes', time: 15 },
      { id: 1, title: '25 minutes', time: 25 },
      { id: 2, title: '45 minutes', time: 45 },
      {
        id: -1,
        title: <CustomEstInput value={timeEST} onChange={setTimeEST} />,
        time: timeEST,
      },
    ],
    [timeEST],
  );

  const deliveryEstimateOptions = useMemo(
    () => {
      const nextAvailablePickup = Math.max(1, moment(estimatedPickupTs).diff(moment(), 'minutes'));
      return [
        {
          id: 0,
          title: `Next Available Pickup (${nextAvailablePickup} mins)`,
          time: nextAvailablePickup,
        },
        {
          id: -1,
          title: <CustomEstInput value={timeEST} onChange={setTimeEST} />,
          time: timeEST,
        },
      ];
    },
    [timeEST, estimatedPickupTs],
  );

  const isAcceptingDisabled = () => {
    if (isSelfDeliveryOrder) {
      return true;
    }
    if (isOmniPartner(order)) {
      return true;
    }
    /** An estimate must be selected */
    if (isScheduled) {
      return false;
    }
    if (isDelivery && !estimatedPickupTs) {
      return true;
    }

    return selectedEstimateOption?.id === -1
      ? !timeEST
      : !selectedEstimateOption?.time;
  };
  const editOrderButton = isAdmin ? (
    <Tooltip
      open={isOmniPartner(order) ? undefined : false}
      placement="topLeft"
      title="Cannot edit an Omni order"
    >
      <Button
        disabled={isSelfDeliveryOrOmniPartner}
        onClick={() => {
          handleEditOrder(orderId);
        }}
      >
        Edit order
      </Button>
    </Tooltip>
  ) : null;

  const cancelOrderButton = isAdmin ? (
    <Button
      danger
      onClick={() => handleRejectOrder(orderId)}
      loading={rejectLoading}
      disabled={
        isSelfDeliveryOrOmniPartner ||
        (
          order.migration_driver === MIGRATION_DRIVER.TOAST &&
          !order.has_integration_error
        )
      }
    >
      Cancel Order
    </Button>
  ) : null;

  const ORDER_DIALOGUE_SETTING = [
    {
      oStatus: [orderStatus.NEW],
      timeSelection: false,
      timeEstSelection: true,
      buttons: (
        <ButtonWrapper editOrderButton={editOrderButton}>
          {cancelOrderButton}
          <Button
            type="primary"
            onClick={() => handleAcceptOrder(orderId)}
            loading={acceptLoading}
            disabled={isAcceptingDisabled()}
            className="ml-0"
          >
            Accept Order
          </Button>
        </ButtonWrapper>
      ),
    },
    {
      oStatus: [orderStatus.IN_PROGRESS, orderStatus.IN_PROGRESS_UPDATED],
      timeSelection: true,
      timeEstSelection: false,
      buttons: (
        <ButtonWrapper editOrderButton={editOrderButton}>
          {cancelOrderButton}
          <Button
            type="primary"
            onClick={() => handleOrderReady(orderId)}
            loading={readyLoading}
            disabled={isSelfDeliveryOrOmniPartner}
            className="ml-0"
          >
            Order Ready
          </Button>
        </ButtonWrapper>
      ),
    },
    {
      oStatus: [orderStatus.READY, orderStatus.READY_UPDATED],
      timeSelection: true,
      timeEstSelection: false,
      buttons: (
        <ButtonWrapper editOrderButton={editOrderButton}>
          {cancelOrderButton}
          {isDelivery ? null : (
            <Button
              type="primary"
              onClick={() => handleOrderDeliver(orderId)}
              loading={deliverLoading}
              className="ml-0"
            >
              Order Delivered
            </Button>
          )}
        </ButtonWrapper>
      ),
    },
    {
      oStatus: [orderStatus.ON_THE_WAY],
      timeSelection: true,
      timeEstSelection: false,
      buttons: (
        <ButtonWrapper editOrderButton={editOrderButton}>
          <span className="align-self-center">Order is on the way</span>
        </ButtonWrapper>
      ),
    },
    {
      oStatus: [orderStatus.COMPLETED],
      timeSelection: false,
      timeEstSelection: false,
      buttons: (
        <ButtonWrapper editOrderButton={editOrderButton}>
          <span className="align-self-center">Order has been completed</span>
        </ButtonWrapper>
      ),
    },
    {
      oStatus: [orderStatus.DELIVERED],
      timeSelection: false,
      timeEstSelection: false,
      buttons: <ButtonWrapper editOrderButton={editOrderButton} />,
    },
    {
      oStatus: [orderStatus.REJECTED_BY_RESTAURANT],
      timeSelection: false,
      timeEstSelection: false,
      buttons: (
        <ButtonWrapper editOrderButton={editOrderButton}>
          <span className="align-self-center">Order has been rejected</span>
        </ButtonWrapper>
      ),
    },
    {
      oStatus: [orderStatus.CANCELLED_BY_RESTAURANT],
      timeSelection: false,
      timeEstSelection: false,
      buttons: (
        <ButtonWrapper editOrderButton={editOrderButton}>
          <span className="align-self-center">Order has been cancelled</span>
        </ButtonWrapper>
      ),
    },
    {
      oStatus: [orderStatus.NEEDS_APPROVAL],
      timeSelection: false,
      timeEstSelection: false,
      buttons: (
        <ButtonWrapper editOrderButton={editOrderButton}>
          {cancelOrderButton}
          <Button
            type="primary"
            onClick={() => handleApproveOrder(orderId)}
            loading={approveLoading}
            disabled={approveLoading}
            className="ml-0"
          >
            {order.has_dispatcher_error
              ? 'Check and Approve'
              : 'Approve Order'}
          </Button>
        </ButtonWrapper>
      ),
    },
    {
      oStatus: [orderStatus.PAYMENT_SCHEDULED],
      timeSelection: false,
      timeEstSelection: false,
      buttons: (
        <ButtonWrapper editOrderButton={editOrderButton}>
          {cancelOrderButton}
          <Button
            type="primary"
            onClick={() => handleApproveOrder(orderId)}
            loading={approveLoading}
            disabled={approveLoading}
            className="ml-0"
          >
            {order.has_dispatcher_error
              ? 'Check and Approve'
              : 'Approve Order'}
          </Button>
        </ButtonWrapper>
      ),
    },
  ];
  const setting = ORDER_DIALOGUE_SETTING.find((s) => s.oStatus.includes(order.state));

  const checkDispatcher = useCallback(() => {
    checkExistDispatcher(orderId)
      .then((result) => {
        setCanRequestDispatcher(result?.status === 200);
      })
      .catch((e) => {
        captureException(e);
        setCanRequestDispatcher(false);
      });
  }, [orderId]);

  useEffect(() => {
    if (!order.id) {
      return;
    }
    if (!isDelivery && !selectedEstimateOption) {
      setSelectedEstimateOption(estimateOptions[0]);
    } else {
      checkDispatcher();
    }
  }, [isDelivery, estimateOptions, checkDispatcher, selectedEstimateOption, order?.id]);

  useEffect(() => {
    if (!orderId) {
      setOrderDetail({});
      return;
    }
    getOrderDetailByOrderId(orderId)
      .then((res) => {
        const detailData = getData(res);
        setOrderDetail(detailData);
        setOrderTime(detailData?.estimate);
      })
      .catch((e) => {
        captureException(e);
        message.error('Something went wrong!');
      });
  }, [orderId]);

  useEffect(() => {
    if (estimatedPickupTs && isDelivery && !selectedEstimateOption) {
      setSelectedEstimateOption(deliveryEstimateOptions[0]);
    }
  }, [estimatedPickupTs, isDelivery, deliveryEstimateOptions, selectedEstimateOption]);

  useEffect(() => {
    if (!orderId) {
      return;
    }
    setEstimatedPickupTs(null);
    if (order.state === 0 && isDelivery) {
      isRideServiceAvailable(orderId).then((res) => {
        const error = getData(res)?.order?.error;
        if (!error) {
          estimateOrder(orderId).then((res) => {
            setEstimatedPickupTs(getData(res)?.pickupts);
          });
        } else {
          messageApi.error(error);
        }
      });
    }
  }, [isDelivery, orderId, order.state]);

  const handleAcceptOrder = (id) => {
    if (!isScheduled) {
      /** On demand */
      if (Number(selectedEstimateOption?.time || timeEST) <= 0) return;
      if (isDelivery && !selectedEstimateOption?.time && !timeEST) {
        return;
      }

      if (isDeliveryEstimateValid()) {
        setAcceptLoading(true);

        updateOrderState(id, {
          state: 1,
          estimated_time: parseInt(selectedEstimateOption?.time || timeEST, 10),
        })
          .then((res) => {
            setAcceptLoading(false);
            handleOrderUpdate(id, getData(res));
            onClose();
          })
          .catch((err) => {
            captureException(err);
            setAcceptLoading(false);
            message.error(getHttpErrorMessage(err));
          });
      } else {
        message.error('Custom time cannot be less than earliest pickup time');
      }
      return;
    }
    /** Scheduled */
    setAcceptLoading(true);
    updateOrderState(id, {
      state: 1,
    })
      .then((res) => {
        setAcceptLoading(false);
        handleOrderUpdate(id, getData(res));
        onClose();
      })
      .catch((err) => {
        captureException(err);
        setAcceptLoading(false);
        message.error(getHttpErrorMessage(err));
      });

    function isDeliveryEstimateValid() {
      /** If it's delivery type, customEstimate should be less than earliest pickup time*/
      if (
        isDelivery &&
          Number(selectedEstimateOption?.time || timeEST) >=
            moment(estimatedPickupTs).diff(moment(), 'minutes')
      ) {
        /** Delivery type */
        return true;
      }
      if (order.order_type === 1) {
        /** Pickup type */
        return true;
      }
      return false;
    }
  };

  const handleRejectOrder = (id) => {
    setRejectLoading(true);
    updateOrderState(id, {
      state: 2,
    })
      .then((res) => {
        setRejectLoading(false);
        handleOrderUpdate(id, getData(res));
        onClose();
      })
      .catch((err) => {
        captureException(err);
        setRejectLoading(false);
        message.error(getHttpErrorMessage(err));
      });
  };

  const handleApproveOrder = (id) => {
    setApproveLoading(true);
    approveOrder(id)
      .then((res) => {
        handleOrderUpdate(id, getData(res));
        onClose();
      })
      .catch((err) => {
        captureException(err);
        message.error(getHttpErrorMessage(err));
      })
      .finally(() => {
        setApproveLoading(false);
      });
  };

  const handleOrderReady = (id) => {
    setReadyLoading(true);
    updateOrderState(id, {
      state: 4,
    })
      .then((res) => {
        setReadyLoading(false);
        onClose();
      })
      .catch((err) => {
        captureException(err);
        setReadyLoading(false);
        message.error(getHttpErrorMessage(err));
      });
  };

  const isEstimatedTimePassed = (time) => {
    const remainingTime = moment(time).diff(moment(), 'minutes');

    if (remainingTime && remainingTime < 0) {
      return true;
    }
    return false;
  };

  const showEStSelection = !isScheduled && setting?.timeEstSelection;

  const handleUpdateOrder = (id, time) => {
    setTimeConfirmLoading(true);
    updateOrderState(id, {
      state: 7,
      estimated_time: parseInt(time, 10),
    })
      .then((res) => {
        setOrderTime(getData(res).estimate);
        handleOrderUpdate(id, getData(res));
        setTimeConfirmLoading(false);
        setShowTimeModal(false);
      })
      .catch((err) => {
        captureException(err);
        setTimeConfirmLoading(false);
        setShowTimeModal(false);
      });
  };

  const handleOrderDeliver = (id) => {
    setDeliverLoading(true);
    updateOrderState(id, {
      state: orderStatus.DELIVERED,
    })
      .then((res) => {
        setDeliverLoading(false);
        handleOrderUpdate(id, getData(res));
        onClose();
      })
      .catch((err) => {
        captureException(err);
        setDeliverLoading(false);
      });
  };

  const isMigratedData = useMemo(
    () => order.migration_id &&
      order.migration_driver !== 'MealMe' &&
      order.migration_driver !== 'GFOE' &&
      order.migrated_json_order_details?.items?.length,
    [order],
  );

  const orderItems = useMemo(
    () => (isMigratedData && Array.isArray(order.migrated_json_order_details?.items)
      ? order.migrated_json_order_details?.items?.map((item) => ({
        ...item,
        total_value: getItemValue({ value: item.value, migrationDriver: order.migration_driver }),
      }))
      : order.order_items),
    [order, isMigratedData],
  );

  const transformDetailLabel = (label) => {
    const capitalizeLabel = (text) => text.charAt(0).toUpperCase() + text.slice(1);
    return capitalizeLabel(label.split('_').join(' '));
  };

  const findDriver = () => {
    setCanRequestDispatcher(false);
    createDispatcherJob({ id: orderId, organizationId })
      .then((result) => {
        messageApi.open({
          type: 'success',
          content: 'Successfully create a job for dispatcher',
        });
      })
      .catch((e) => {
        captureException(e);
        messageApi.open({
          type: 'error',
          content: 'Something went wrong during request a driver!',
        });
        setCanRequestDispatcher(true);
      });
  };

  const showFindDriverButton = useMemo(
    () => order.state === orderStatus.NEEDS_APPROVAL ||
      order.state === orderStatus.NEW ||
      (order.state === orderStatus.IN_PROGRESS && canRequestDispatcher),
    [order, canRequestDispatcher],
  );

  function renderTitle() {
    return (
      <div className="flex pr-6 items-start flex-column flex-md-row gap-2">
        <div className="flex-1 flex items-start items-sm-center flex-column flex-sm-row gap-2">
          Order #
          {order.number}
          {' - '}
          {orderId}
          <Tag className="ml-0 sm:ml-2">{getOrderType(order)}</Tag>
        </div>

        <Typography.Text type="secondary" className="justify-end">
          Placed on:
          {' '}
          {moment(order.timestamp).format('MMM D, LT')}
        </Typography.Text>
      </div>
    );
  }
  function renderAlerts() {
    return (
      <>
        {order.has_dispatcher_error && (
          <Alert
            message="There seems to be an issue with the order. To ensure a resolution, please contact Omnicart."
            type="error"
            showIcon
            closable
            className="mb-4 mx-4"
          />
        )}
        {order.state === orderStatus.NEEDS_APPROVAL && (
          <Alert
            message="Auto sending has been turned off for this merchant. Send order to merchant so they can view it on their tablet."
            type="warning"
            showIcon
            closable
            className="mb-4 mx-4"
          />
        )}
        {order.has_integration_error && (
          <Alert
            message="Order not processed. Please reorder using the provided link or call the merchant directly.            "
            type="warning"
            showIcon
            closable
            className="mb-4 mx-4"
          />
        )}
      </>
    );
  }
  function renderCustomerInfo() {
    return (
      <Row divider={false} className="flex-column flex-md-row items-start">
        <Space direction="horizontal">
          {order.is_gift
            ? `Sender: ${order.gift_sender_name || order.customer?.name}`
            : order.customer?.name}
          {[PaymentTypes.CASH, PaymentTypes.ALTERNATE_PAYMENT].includes(
            order.payment_type,
          ) && (
            <Typography.Text type="secondary" className="text-xs">
              {order.payment_type === PaymentTypes.CASH
                ? '(Cash Payment)'
                : `(Alt Payment‍‍ ${order.alternate_payment_number})`}
            </Typography.Text>
          )}
        </Space>

        <Space size={isDesktop ? 'large' : 'small'} className="flex-column flex-md-row items-start">
          <JumpLink href={`tel:${order.customer?.phone}`} ellipsis={false}>
            <PhoneOutlined className="mr-1" />
            {order.customer?.phone}
          </JumpLink>
          <JumpLink href={`mailto:${order.customer?.email}`} maxWidth={200}>
            <MailOutlined className="mr-1" />
            {order.customer?.email}
          </JumpLink>
        </Space>

        {order.external_order_details?.tracking_link && (
          <JumpLink
            target="_blank"
            rel="noreferrer noopener"
            href={order.external_order_details?.tracking_link}
          >
            Support
          </JumpLink>
        )}
      </Row>
    );
  }
  function renderGiftInfo() {
    return (
      order.is_gift && (
        <Row>
          <Space direction="vertical">
            <JumpLink href={`tel:${order.recipient_number}`}>
              Recipient:
              {' '}
              {order.recipient_number}
            </JumpLink>
            <Typography.Paragraph italic>
              Gift message:
              {order.gift_message}
            </Typography.Paragraph>
          </Space>
        </Row>
      )
    );
  }
  function renderAddressInfo() {
    return (
      isDelivery && (
        <Row className="flex-column flex-md-row">
          <div className="flex-1">
            <Value
              label="Address"
              value={order.address?.address}
              direction="horizontal"
              className="align-items-start"
            />
          </div>

          <JumpLink
            target="_blank"
            rel="noreferrer"
            href={`https://google.com/maps?q=${order.address?.location?.lat},${order.address?.location?.lng}`}
            className="justify-self-end"
          >
            Direction
          </JumpLink>
        </Row>
      )
    );
  }
  function renderScheduleInfo() {
    return (
      isScheduled && (
        <Row>
          <Value
            direction="horizontal"
            label={(
              <>
                <CalendarOutlined className="mr-2" />
                Scheduled for
                {' '}
              </>
            )}
          >
            <Typography.Text>
              {moment(order.scheduled_for).format('ddd MMM DD, LT')}
              -
              {moment(order.scheduled_for).add(0.5, 'hour').format('LT')}
            </Typography.Text>
          </Value>
        </Row>
      )
    );
  }
  function renderDeliveryInfo() {
    return (
      isDelivery && (
        <Row className="flex-column flex-md-row gap-4">
          <Value
            label="Delivery status"
            value={
              RIDE_STATUS.find((s) => s.id === order.delivery_state)?.title ||
              '—'
            }
          />

          <Value
            label="Pickup ETA"
            value={
              orderDetail?.estimated_pickup_time
                ? `${moment(orderDetail?.estimated_pickup_time).format(
                  'MMM DD, hh:mm A',
                )} (${moment(orderDetail?.estimated_pickup_time).fromNow()})`
                : '—'
            }
          />
          <Value label="Driver">
            <Typography.Text>
              {order.driver_name ? order.driver_name : '—'}
              {' '}
              {order.driver_phone && (
                <a
                  className="driver-phone-link"
                  href={`tel:${order.driver_phone}`}
                >
                  <span>Call</span>
                </a>
              )}
            </Typography.Text>
          </Value>

          {order.dispatcher_external_link && (
            <Value label="Dispatch">
              <JumpLink
                href={order.dispatcher_external_link}
                rel="noopener noreferrer"
                target="_blank"
              >
                Go to Dispatch
              </JumpLink>
            </Value>
          )}
          {showFindDriverButton && (
            <Button disabled={!canRequestDispatcher} onClick={findDriver}>
              {canRequestDispatcher ? 'Find a driver' : 'Requested'}
            </Button>
          )}
        </Row>
      )
    );
  }
  function renderEstimateInfo() {
    if (isScheduled || order.state === orderStatus.ON_THE_WAY || !setting?.timeSelection) {
      // If the order is scheduled or it's on the way, we cannot update the time
      return null;
    }
    return (
      <Row>
        <Value
          direction="horizontal"
          label={(
            <>
              {isEstimatedTimePassed(orderTime) && (
                <WarningOutlined style={{ color: 'red' }} className="mr-2" />
              )}
              Estimated time
            </>
          )}
          value={moment(orderTime).fromNow()}
        />
        <Button onClick={() => setShowTimeModal(true)}>Update Time</Button>
      </Row>
    );
  }
  function renderItemsInfo() {
    const modifiers = (item) => (item.order_options?.length > 0
      ? item.order_options
      : item.order_modifier);
    return (
      <Row title="Items">
        <List
          className="w-full"
          itemLayout="horizontal"
          size="small"
          dataSource={orderItems || []}
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta
                title={(
                  <div className="flex justify-between">
                    <div>

                      {item.qty && (
                        <>
                          {item.qty}
                          <span className="mx-2">x</span>
                        </>
                      )}
                      {' '}
                      {item.item_name}
                      {' '}
                      {item.size?.trim() && `(${item.size})`}
                      {' '}
                    </div>
                    {formatAmount(item.total_value)}
                  </div>
                )}
                description={(
                  <>
                    {modifiers(item).length > 0 && (
                      <span className="ml-8 block">
                        {(modifiers(item))
                          .map(
                            (m) => `${m.qty > 1 ? m.qty : ''} ${
                              m.modifier_option?.title || m.title
                            } (${m.modifier_option?.price >= 0 ? `$${m.modifier_option?.price}` : m.price})`,
                          )
                          .join(' · ')}

                      </span>
                    )}
                    {item.message && (
                      <Space className="flex ml-8">
                        <MessageOutlined />
                        {item.message}
                      </Space>
                    )}
                    {item.item_related?.catering_label && (
                      <Space className="flex ml-8 mt-2" size="small">
                        <InfoCircleOutlined />
                        <span>
                          Each
                          {item.item_related?.catering_label}
                          {' '}
                          will serve
                          {item.item_related?.catering_unit_per_label || 1}
                          {' '}
                          people
                        </span>
                      </Space>
                    )}
                  </>

                )}
              />
            </List.Item>
          )}
        />
      </Row>
    );
  }
  function renderNoteInfo() {
    return (
      <>
        {order.admin_note && (
          <Row>
            <Value
              direction="horizontal"
              label="Admin Note"
              value={order.admin_note}
            />
          </Row>
        )}
        {order.delivery_message && (
          <Row>
            <Value
              direction="horizontal"
              label="Customer Note"
              value={order.delivery_message}
            />
          </Row>
        )}
      </>
    );
  }
  function renderFinancialInfo() {
    let fields;
    window.order = order;
    if (!isAdmin) {
      fields = [
        ['Subtotal', formatAmount(order.food_real_value)],
        ['Tax', formatAmount((order.tax_rate || 0) * order.food_real_value)],
        ['Merchant Net', formatAmount(order.restaurant_net)],
        ['Payment Fees', formatAmount(order.order_nets?.restaurant_fee)],
      ];
    } else {
      fields = [
        ['Total', formatAmount(order.value)],
        ['Subtotal', formatAmount(order.food_value)],
        ['Subtotal Without Markup', formatAmount(order.food_real_value)],
        ['Tax', formatAmount(order.order_nets?.total_tax)],
        ['Merchant Tip', formatAmount(order.order_nets?.restaurant_tip_amount)],
        ['Merchant Net', formatAmount(order.restaurant_net)],
        ['Delivery Fee', formatAmount(order.delivery_fare)],
        ['Driver tip', formatAmount(order.order_nets?.rideshare_tip_amount)],
        ['Service Fee', formatAmount(order.service_fee)],
        [
          'Coupon',
          order.coupon_amount != null ? formatAmount(order.coupon_amount) : '-',
        ],
        ['Adjustment Amount', formatAmount(order.adjustment_amount)],
        [
          'Payment Fee',
          formatAmount(order.order_nets?.stripe || order.order_nets?.moneris),
        ],
      ];
    }

    const paymentDetails = order.payment?.custom_order_payment ? (
      <Row>
        <Space direction="vertical">
          <Value
            label={(
              <Space>
                <CreditCardOutlined />
                <Typography.Text style={{ color: 'inherit' }}>Payment</Typography.Text>
              </Space>
            )}
            direction="horizontal"
            value={order.payment.custom_order_payment?.custom_payment?.title || 'Custom'}
          />
          <Space className="pl-5" direction="vertical">
            {
              Object.entries(order.payment.custom_order_payment?.meta ?? {}).map(([key, value]) => (
                <Value
                  label={key}
                  value={value}
                  direction="horizontal"
                />
              ))
            }
          </Space>
        </Space>

      </Row>
    ) : null;
    return (
      <>
        {paymentDetails}
        <Row className="items-center mt-4">
          {renderItems()}
        </Row>
      </>

    );

    function renderItems() {
      return (
        <Descriptions
          bordered
          size="small"
          column={1}
          contentStyle={{ whiteSpace: 'nowrap' }}
          className="overflow-auto"
        >
          {
            isMigratedData && order.migrated_json_order_details?.details ?
              Object.entries(order.migrated_json_order_details.details)?.map(
                ([key, value]) => (
                  <Descriptions.Item key={key} label={transformDetailLabel(key)}>
                    {value}
                  </Descriptions.Item>
                ),
              ) :
              fields.map(([title, value]) => (
                <Descriptions.Item key={title} label={title}>
                  {value}
                </Descriptions.Item>
              ))

          }
        </Descriptions>
      );
    }
  }
  function renderEstimateForm() {
    const options = isDelivery ? deliveryEstimateOptions : estimateOptions;

    return (
      showEStSelection && (
        <Row>
          <Value label="Estimated time">
            {!isDelivery || estimatedPickupTs ? (
              <Radio.Group
                value={selectedEstimateOption?.id}
                onChange={({ target: { value } }) => setSelectedEstimateOption(
                  options.find((o) => +o.id === +value),
                )}
              >
                {options.map((option) => (
                  <Radio value={option.id}>{option.title}</Radio>
                ))}
              </Radio.Group>
            ) : (
              <Skeleton paragraph={{ rows: 1 }} />
            )}
            <Typography.Text type="secondary">
              Pick a time that accurately estimates how long this order will
              take to prepare.
            </Typography.Text>
          </Value>
        </Row>
      )
    );
  }
  function renderMerchantInfo() {
    const {
      has_integration_error: hasIntegrationError,
      integration_merchant_link: merchantLink,
      integration_merchant_phone_number: merchantPhone,
    } = order || {};

    if (!hasIntegrationError) {
      return null;
    }
    return (
      <Row>
        <Value label="Merchant name" value={order.merchant?.name} />
        <Space align="end">
          <Value label="Merchant phone number">
            {merchantPhone && (
              <JumpLink href={`tel:${merchantPhone}`}>
                <Space>
                  <PhoneOutlined />
                  { merchantPhone}
                </Space>
              </JumpLink>
            )}
          </Value>
          {merchantLink && (<JumpLink href={merchantLink}>Ordering link</JumpLink>)}
        </Space>
      </Row>

    );
  }

  function renderContent() {
    if (!order.customer) {
      return <Skeleton paragraph={{ rows: 10 }} className="p-4" />;
    }
    return (
      <div
        className="py-4"
        style={{
          maxHeight: '70vh',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        {contextHolder}
        {renderAlerts()}
        {renderCustomerInfo()}
        {renderMerchantInfo()}
        {renderGiftInfo()}
        {renderAddressInfo()}
        {renderScheduleInfo()}
        {renderDeliveryInfo()}
        {renderEstimateInfo()}
        {renderItemsInfo()}
        {renderNoteInfo()}
        {renderEstimateForm()}
        {renderFinancialInfo()}
        {showTimeModal && (
          <TimeDialogue
            handleCancel={() => setShowTimeModal(false)}
            handleConfirm={(time) => handleUpdateOrder(orderId, time)}
            loading={timeConfirmLoading}
          />
        )}
        <SendReceiptSection order={order} />
      </div>
    );
  }

  return (
    <Modal
      wrapClassName={`${open ? 'flex' : 'hidden'} items-center justify-between`}
      className="margin-0 top-10 md:top-0"
      footer={setting?.buttons ?? null}
      title={renderTitle()}
      getContainer={false}
      onCancel={() => {
        setOrderDetail({});
        onClose();
      }}
      classNames={{
        body: 'p-1',
      }}
      open={open}
      width={720}
      style={{ maxWidth: '90vw' }}
    >
      {renderContent()}
    </Modal>
  );
}

function Value({
  label, value, children, ...props
}) {
  return (
    <Space direction="vertical" {...props}>
      <Typography.Text type="secondary" style={{ wordBreak: 'normal', textTransform: 'capitalize' }}>{label}</Typography.Text>
      {children || <Typography.Text>{value}</Typography.Text>}
    </Space>
  );
}

function Row({
  children,
  className = 'items-center',
  divider = true,
  title = null,
  ...props
}) {
  if (!children) {
    return null;
  }
  return (
    <>
      {divider && (
        <Divider
          className="my-4"
          orientation="left"
        >
          {title && <Typography.Text type="secondary">{title}</Typography.Text>}
        </Divider>
      )}
      <div
        {...props}
        className={`flex justify-between w-full px-4 ${className}`}
      >
        {children}
      </div>
    </>
  );
}

function CustomEstInput({ value, onChange, disabled = false }) {
  const [errorOpen, setErrorOpen] = useState(false);
  return (
    <Space>
      <Popconfirm
        open={errorOpen}
        title="Invalid Time"
        description="Custom time cannot be more than 60 minutes"
        onConfirm={() => setErrorOpen(false)}
        showCancel={false}
      >
        <Input
          type="number"
          value={value}
          onKeyDown={(e) => {
            ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
          }}
          onChange={({ target: { value: val } }) => {
            if (val === '' || (val > 0 && val <= 60)) {
              onChange(val);
            } else if (val > 60) {
              onChange(60);
              setErrorOpen(true);
            }
          }}
          disabled={disabled}
          placeholder="Custom time"
        />
      </Popconfirm>
      <span>min</span>
    </Space>
  );
}
function ButtonWrapper({ editOrderButton, children }) {
  return (
    <div className="flex justify-between flex-column flex-sm-row gap-4">
      {editOrderButton || <span />}
      {children && (
        <div className="flex flex-column flex-sm-row gap-4">
          {children}
        </div>
      )}
    </div>
  );
}
OrderModal.propTypes = {};

function SendReceiptSection({ order }) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState();
  const { message } = App.useApp();

  function handleSend() {
    setLoading(true);
    http.post(`/v1/orders/${order.id}/send-email/`, { emails: [email] }).then(() => {
      message.success('Email sent');
    }).catch((e) => {
      message.error(getHttpErrorMessage(e));
    }).finally(() => {
      setLoading(false);
    });
  }
  return (
    <>
      <Divider />
      <Typography.Title level={4}>
        Send order detail via email
      </Typography.Title>
      <Typography.Paragraph type="secondary">
        By entering your email address you can get this order’s details.
      </Typography.Paragraph>
      <Flex gap={16} style={{ maxWidth: 400 }}>
        <Form onFinish={handleSend}>
          <Flex gap={16}>
            <FormItem
              rules={
                [{
                  required: true,
                  message: 'Please enter your email',
                }]
              }
            >
              <Input type="email" value={email} onChange={({ target: { value } }) => { setEmail(value); }} />
            </FormItem>
            <Button htmlType="submit" loading={loading} disabled={loading}>
              Send
            </Button>
          </Flex>

        </Form>

      </Flex>
    </>
  );
}
export default withGuard(OrderModal, [PERMISSIONS.ORDERS], { redirect: true });
