import {
  Button, Drawer, Flex,
} from 'antd';
import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import MenuItems from './MenuItems';

function AddOrderItemOverlay({
  open, onClose, onAdd, merchantId, nested,
}) {
  const [rerenderKey, setRerenderKey] = useState(0);
  const handleClose = () => {
    onClose();
    setRerenderKey(rerenderKey + 1);
  };
  return (
    <Drawer
      title="Add Item"
      placement="right"
      onClose={handleClose}
      open={open}
      size="large"
      width={nested ? 600 : 700}
      footer={(
        <Flex gap={8}>
          <Button
            key="back"
            onClick={handleClose}
          >
            Close
          </Button>
        </Flex>
      )}
    >
      <MenuItems
        rerenderKey={rerenderKey}
        merchantId={merchantId}
        onAdd={(item) => {
          onAdd({
            id: uuid(),
            item_related: item,
            qty: 1,
            isNew: true,
            real_price: Number(item.real_price || item.value),
          });
        }}
      />
    </Drawer>
  );
}

export default AddOrderItemOverlay;
