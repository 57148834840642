import { InputNumber } from 'antd';
import { TagSelector } from '../../../components/DietaryTags/TagSelector';
import { ModalMode } from 'containers/MenuManagement/constants';
import { ModifierContext } from 'containers/MenuManagement/context';
import ModifierModal from 'containers/MenuManagement/Modifiers/ModifierModal/index';
import { orderBy } from 'lodash-es';
import React, {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import DraggableTable from '../../../components/DraggableTable';
import { OptionMode } from '../constants';
import columns from './columns';
import OptionSelector from './OptionSelector';

function OptionsTable({ options, setOptions }) {
  const { modifiers } = useContext(ModifierContext);
  const [editingOption, setEditingOption] = useState();
  const tempEditingOption = useRef({});
  const [mode, setMode] = useState(ModalMode.CLOSED);
  const onRemoveItem = (e, id) => {
    e.preventDefault();
    const newOptions = options.map((o) => {
      if (o.id ? o.id === id : o.localId === id) {
        o.type = 'DELETED';
      }
      return o;
    });
    setOptions(newOptions);
  };

  const onSave = () => {
    const editedOptions = [...options];
    const index = editedOptions.findIndex((o) => o.id === tempEditingOption.current.id);
    editedOptions[index] = { ...tempEditingOption.current, type: OptionMode.UPDATED };
    setOptions(editedOptions);
    setEditingOption();
    tempEditingOption.current = {};
  };

  const getNestedOptions = useCallback((modifierChildren) => {
    if (typeof modifierChildren[0] !== 'number') return modifierChildren;
    const nestedOptions = [];
    modifierChildren.forEach((childId) => {
      const modifier = modifiers.find((m) => m.id === childId);
      nestedOptions.push(modifier);
    });
    return nestedOptions;
  }, [modifiers]);

  const tableColumns = useMemo(() => columns({
    editingOption,
    tempEditingOption,
    setEditingOption,
    onRemoveItem,
    onSave,
    getNestedOptions,
  }).map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'title' ? 'text' : col.dataIndex === 'dietary_tags' ? 'tag' : 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: record.key === editingOption?.key,
      }),
    };
  }), [editingOption, tempEditingOption, tempEditingOption, onRemoveItem, getNestedOptions]);

  useEffect(() => {
    if (editingOption && editingOption.modifiers_children?.length) {
      setMode(ModalMode.EDIT);
    }
  }, [editingOption]);

  const sortedOptions = useMemo(() => {
    const indexedOptions = options?.map((option) => ({
      ...option,
      key: (option.id || option.localId),
    })).filter((o) => o.type !== OptionMode.DELETED) || [];
    return orderBy(indexedOptions, ['position'], ['asc']);
  }, [options]);

  const reorderPositions = (items) => {
    const reorderedOptions = items.map((item, index) => ({
      ...item,
      position: index + 1,
      type: item.position === index + 1 ? item.type : OptionMode.UPDATED,
    }));
    setOptions(reorderedOptions);
  };

  const onChangeOption = (key, value) => {
    tempEditingOption.current = { ...tempEditingOption.current, [key]: value };
  };

  const editingNestedOption = useMemo(() => (editingOption && editingOption.modifiers_children?.length ?
    modifiers?.find((m) => m.id === editingOption.modifiers_children?.[0]?.id) : editingOption), [editingOption]);
  return (
    <>
      <DraggableTable
        columns={tableColumns}
        dataSource={sortedOptions}
        setDataSource={reorderPositions}
        showHeader={false}
        CustomCell={(props) => <EditableCell {...props} onChange={onChangeOption} />}
      />
      {editingOption && editingOption.modifiers_children?.length > 0 &&
      <ModifierModal mode={mode} setMode={setMode} modifier={editingNestedOption} />}
    </>
  );
}

function EditableCell({
  editing,
  inputType,
  record,
  index,
  children,
  onCancel,
  onSave,
  onChange,
  ...restProps
}) {
  if (editing && !record.modifiers_children?.length) {
    return (
      <td {...restProps}>
        {' '}
        {inputType === 'text' ?
          <OptionSelector option={record.title} setOption={(option) => onChange('title', option.value)} /> :
          inputType === 'tag' ?
            <TagSelector defaultValue={record.dietary_tags} onChangeDetail={(value) => onChange('dietary_tags', value)} maxTagCount={1} /> : (
              <InputNumber
                value={record.real_price}
                type="number"
                placeholder="Price"
                controls={false}
                prefix="$"
                style={{ width: '100%' }}
                onChange={(real_price) => onChange('real_price', real_price)}
              />
            )}
      </td>
    );
  }
  return (
    <td {...restProps}>
      {children}
    </td>
  );
}

export default OptionsTable;
