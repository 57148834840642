import { PlusSquareOutlined } from '@ant-design/icons';
import {
  Flex, Image, List, Select, Skeleton, Typography,
} from 'antd';
import { Colors } from 'assets/colors';
import { fallbackItemImage } from 'containers/CreateOrder/constants';
import useIsMobile from 'hooks/useIsMobile';
import React, { useEffect, useMemo, useState } from 'react';
import { getImage } from 'utils/image';
import { getItemPrices } from '../ItemDetailOverlay/item';
import ItemPrice from './ItemPrice';
import SearchItemInput from './SearchItemInput';
import { fetchMenus } from './requests';

function MenuItems({
  merchantId, scheduledFor, onAdd,
}) {
  const isMobile = useIsMobile();
  const [loading, setLoading] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState();
  const [menus, setMenus] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setLoading(true);
    fetchMenus({ merchantId, scheduledTime: scheduledFor }).then((data) => {
      const fetchedMenus = data.results?.map((menu) => ({
        ...menu,
        value: menu.id,
        label: menu.name,
      }));
      setMenus(fetchedMenus);
      setSelectedMenu(fetchedMenus[0]);
    }).finally(() => {
      setLoading(false);
    });
  }, [merchantId, scheduledFor]);

  const categories = useMemo(() => {
    const allCats = selectedMenu?.categories ?? [];
    const terms = searchTerm.toLowerCase().split(' ').filter((s) => s);
    if (!terms.length) {
      return allCats.filter((cat) => cat.items?.length > 0);
    }
    return allCats.map((c) => ({
      ...c,
      items: c.items
        .filter(({ name }) => terms.some((term) => name.toLowerCase().includes(term))),
    }))
      .filter((cat) => cat.items?.length > 0);
  }, [selectedMenu, searchTerm]);

  const onMenuChange = (val) => {
    setSelectedMenu(menus.find((m) => m.id === val));
  };

  return (
    <>
      <Flex gap={16}>
        <Select
          placeholder="Select a menu"
          className="pr-0 md:pr-1 mb-2 lg:mb-0"
          style={{ width: 250 }}
          onChange={onMenuChange}
          options={menus}
          value={selectedMenu}
          disabled={loading}
          loading={loading}
        />
        <SearchItemInput
          style={{ width: 250 }}
          value={searchTerm}
          onChange={setSearchTerm}
        />
      </Flex>
      <div
        id="scrollableDiv"
        style={{
          overflow: 'auto',
          marginTop: 8,
          paddingRight: isMobile ? 0 : 10,
        }}
      >
        {loading ? (
          <Skeleton paragraph={{ rows: 5 }} className="mt-4" />
        ) : (
          categories?.map((cat) => (
            <List
              key={cat.id}
              header={<div>{cat.name}</div>}
              dataSource={cat?.items}
              style={{ paddingRight: 8 }}
              renderItem={(item) => (
                <List.Item>
                  <Flex gap={8} className="w-full">
                    <Image
                      width={isMobile ? 32 : 45}
                      height={isMobile ? 32 : 45}
                      style={{ borderRadius: 4, border: '1px solid #DDE1E4' }}
                      src={getImage({ data: item })}
                      fallback={fallbackItemImage}
                      preview={Boolean(getImage({ data: item }))}
                    />

                    <div className="flex flex-row justify-between items-center w-100">
                      <div className="flex flex-column justify-between items-start" style={{ width: '95%' }}>
                        <Typography.Text
                          style={{ width: '100%' }}
                          ellipsis={{
                            tooltip: item?.name,
                          }}
                        >
                          {item?.name}
                          {' '}
                        </Typography.Text>
                        <ItemPrice
                          price={getItemPrices({ item })?.price}
                          discountedPrice={getItemPrices({ item })?.discountedPrice}
                          style={{ paddingLeft: 14 }}
                        />
                      </div>
                      <PlusSquareOutlined
                        onClick={() => {
                          onAdd(item);
                        }}
                        style={{ color: Colors.primary1, fontSize: 20 }}
                      />
                    </div>
                  </Flex>
                </List.Item>
              )}
            />
          ))
        )}
      </div>
    </>
  );
}

export default MenuItems;
