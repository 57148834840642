/* eslint-disable camelcase */
import useIsMobile from 'hooks/useIsMobile';
import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Form,
  Input,
  Select,
  DatePicker,
  Typography,
  Flex,
  Divider,
  Skeleton,
  App,
  Button,
} from 'antd';
import SwitchInput from 'components/SwitchInput';
import FormItem from 'components/FormItem';
import FormLayout from 'components/FormLayout';
import MultiTypeAmountInput from 'components/MultiTypeAmountInput';
import { DeliveryIcon, PickupIcon } from 'assets/icons';
import RegionInput from 'components/RegionInput';
import { useParams } from 'react-router-dom';
import { getHttpErrorMessage } from 'services/http';
import dayjs from 'dayjs';
import { EMPTY_OBJECT } from 'constants';
import CentInput from 'components/CentInput';
import ActiveHoursInput from './ActiveHoursInput';
import Tip from './TipSection';
import TimezoneInput from './TimezoneInput';

function DrawerForm({
  data = EMPTY_OBJECT,
  loading,
  open,
  onClose,
  title,
  onSubmit,
  onRemove,
}) {
  return (
    <Drawer
      title={title}
      placement="right"
      onClose={onClose}
      open={open}
      size="large"
      styles={{ body: { padding: 0, paddingBottom: 64 } }}
    >
      {loading ? (
        <FormLayout.Section divider={false}>
          <Skeleton paragraph={{ rows: 10 }} />
        </FormLayout.Section>
      ) : (
        <LocationForm
          data={data}
          onSubmit={onSubmit}
          onRemove={onRemove}
          onClose={onClose}
        />
      )}
    </Drawer>
  );
}

function LocationForm({
  data, onSubmit, onRemove, onClose,
}) {
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!data) return;
    form.setFieldsValue(deserialize(data));
  }, [data, form]);

  const handleSubmit = async () => {
    setLoading(true);
    onSubmit(serialize(form.getFieldsValue()))
      .then((res) => {
        message.success(`Location ${id ? 'updated' : 'created'} successfully!`);
        form.setFieldsValue(deserialize(res.data));
      })
      .catch((e) => {
        message.error(getHttpErrorMessage(e));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onValuesChange={() => {
        const data = form.getFieldsValue();
        console.log('values:', data);
      }}
      onFinishFailed={() => {
        message.error('Please fix errors!');
      }}
      onFinish={handleSubmit}
      initialValues={deserialize(data)}
    >
      <FormLayout.Section divider={false}>
        {/* <div
          className="p-4 mb-4"
          style={{ background: '#E6F3EB', borderRadius: 4 }}
        >
          <FormItem name="is_enabled" noStyle boolean fullWidth>
            <SwitchInput
              label="Mark as active"
              description="It allows administrators to set a specific location as currently operational and accepting orders."
            />
          </FormItem>
        </div> */}
        <FormItem name="title" label="Name" rules={[{ required: true }]}>
          <Input />
        </FormItem>
        <FormItem name="support_email" label="Support email">
          <Input type="email" />
        </FormItem>
        <FormItem name="support_phone_number" label="Support phone number">
          <Input type="tel" />
        </FormItem>
        <FormItem name="currency" label="Currency">
          <Select
            options={[
              { value: 'USD', label: 'USD' },
              { value: 'CAD', label: 'CAD' },
            ]}
          />
        </FormItem>
        <FormItem
          name="timezone"
          label="Time zone"
          rules={[{ required: true }]}
        >
          <TimezoneInput />
        </FormItem>
      </FormLayout.Section>
      <FormLayout.Section>
        <FormItem name="closed_until_enabled" boolean fullWidth>
          <SwitchInput
            label="Closed until"
            description={(
              <>
                Allows merchant owners to set a temporary deactivation of their
                store, automatically reactivating it at a pre-specified time.
                <FormItem shouldUpdate>
                  {() => (
                    <FormItem name="closed_until" className="mt-2">
                      <DatePicker
                        showTime
                        disabled={!form.getFieldValue('closed_until_enabled')}
                        changeOnBlur
                      />
                    </FormItem>
                  )}
                </FormItem>
              </>
            )}
          />
        </FormItem>
      </FormLayout.Section>
      <FormLayout.Section title="Active hours">
        <FormItem name="active_hours" fullWidth>
          <ActiveHoursInput />
        </FormItem>
      </FormLayout.Section>
      <FormLayout.Section title="Polygon">
        <Typography.Paragraph type="secondary">
          Define delivery areas by drawing polygons on the map to show users
          which merchants are available in this location.
        </Typography.Paragraph>
        <FormItem
          name="multipoly"
          fullWidth
          rules={[
            {
              message: 'Please draw a polygon',
              required: true,
            },
          ]}
        >
          <RegionInput />
        </FormItem>
      </FormLayout.Section>
      <FormLayout.Section title="Delivery radius">
        <Typography.Paragraph type="secondary">
          Set a maximum delivery distance from restaurants to determine service
          availability for users.
        </Typography.Paragraph>
        <FormItem
          name="delivery_radius"
          rules={[MultiTypeAmountInput.RequiredRule]}
        >
          <MultiTypeAmountInput types={{ Km: 'Km', Mi: 'Mi' }} />
        </FormItem>
      </FormLayout.Section>
      <FormLayout.Section title="Service fee" fullWidth>
        <Typography.Paragraph type="secondary">
          The formula calculates the lesser value between the maximum amount and
          the sum of a fixed amount plus a percentage-based amount.
        </Typography.Paragraph>
        <Flex align="center" className="mb-4">
          <DeliveryIcon />
          <Typography.Text strong>Delivery Service Fee</Typography.Text>
        </Flex>

        <Flex vertical={isMobile} gap={isMobile ? 0 : 16} align="start" size="large">
          <Flex vertical>
            <FormItem
              name={['delivery_service_fee', 'fixed_cents']}
              label="Fixed"
            >
              <CentInput />
            </FormItem>
            <FormItem
              name={['delivery_service_fee', 'percentage']}
              label="Percentage"
            >
              <Input prefix="%" />
            </FormItem>
          </Flex>
          <Flex
            className="md:px-4"
            style={!isMobile && { borderLeft: '1px solid #ececec', alignSelf: 'normal' }}
          >
            <FormItem name={['delivery_service_fee', 'max_cents']} label="Max">
              <CentInput />
            </FormItem>
          </Flex>
        </Flex>
        <Divider />
        <Flex align="center" className="mb-4">
          <PickupIcon />
          <Typography.Text strong>Pickup Service Fee</Typography.Text>
        </Flex>

        <Flex vertical={isMobile} gap={isMobile ? 0 : 16} align="start" size="large">
          <Flex vertical>
            <FormItem
              name={['pickup_service_fee', 'fixed_cents']}
              label="Fixed"
            >
              <CentInput />
            </FormItem>
            <FormItem
              name={['pickup_service_fee', 'percentage']}
              label="Percentage"
            >
              <Input prefix="%" />
            </FormItem>
          </Flex>
          <Flex
            className="md:px-4"
            style={!isMobile && { borderLeft: '1px solid #ececec', alignSelf: 'normal' }}
          >
            <FormItem name={['pickup_service_fee', 'max_cents']} label="Max">
              <CentInput />
            </FormItem>
          </Flex>
        </Flex>
        <Divider />
        <FormItem
          name="service_fee_tax_percentage"
          label="Service fee tax percentage"
        >
          <Input prefix="%" placeholder=" e.g. 15" />
        </FormItem>
      </FormLayout.Section>
      <FormLayout.Section>
        <FormItem name="show_special_requests" boolean fullWidth>
          <SwitchInput
            label="Special request available"
            description="It enables users within a specific area to add personalized instructions or preferences to their food delivery orders."
          />
        </FormItem>
      </FormLayout.Section>
      <FormLayout.Section title="Tip options">
        <Typography.Paragraph type="secondary">
          Customers can choose between 4 presets or enter a custom amount.
        </Typography.Paragraph>
        <Tip />
        <Typography.Text strong>Minimum Tip</Typography.Text>
        <Flex gap={16}>
          <FormItem
            name="minimum_tip_amount"
            label="Fixed"
            rules={[{ required: true }]}
          >
            <Input prefix="$" />
          </FormItem>
          <FormItem
            name="minimum_tip_percent"
            label="Percentage"
            rules={[{ required: true }]}
          >
            <Input prefix="%" />
          </FormItem>
        </Flex>
      </FormLayout.Section>
      <FormLayout.Footer>
        <Flex justify="space-between">
          <div>
            {onRemove && (
              <Button danger disabled={loading} onClick={onRemove}>
                Remove
              </Button>
            )}
          </div>
          <Flex gap={4}>
            <Button disabled={loading} onClick={onClose}>
              Cancel
            </Button>
            <Button htmlType="submit" type="primary" loading={loading}>
              Save
            </Button>
          </Flex>
        </Flex>
      </FormLayout.Footer>
    </Form>
  );
}

function serialize({
  multipoly,
  delivery_radius,
  tip_default_value,
  tip_options,
  closed_until_enabled,
  closed_until,
  active_hours,
  ...data
}) {
  return {
    ...data,
    closed_until: closed_until_enabled ? closed_until.format('YYYY-MM-DDTHH:mm:ss') : null,
    tip_default_value: tip_options?.[tip_default_value] || 0,
    tip_options,
    delivery_radius: delivery_radius.amount,
    delivery_radius_unit: delivery_radius.type,
    active_times: ActiveHoursInput.serialize(active_hours),
    multipoly: RegionInput.serialize(multipoly),
  };
}
function deserialize({
  delivery_radius,
  delivery_radius_unit,
  tip_default_value,
  minimum_tip_amount = 0,
  minimum_tip_percent = 0,
  multipoly,
  tip_options = [5, 10, 15, 20],
  closed_until,
  active_times,
  currency = 'USD',
  ...data
}) {
  tip_default_value = tip_options.findIndex((tip) => tip_default_value === tip);
  return {
    ...data,
    delivery_radius: {
      amount: delivery_radius,
      type: delivery_radius_unit,
    },
    active_hours: ActiveHoursInput.deserialize(active_times),
    tip_default_value: tip_default_value === -1 ? 0 : tip_default_value,
    tip_options,
    currency,
    closed_until_enabled: Boolean(closed_until),
    closed_until: closed_until ? dayjs(closed_until.slice(0, -1)) : null,
    multipoly: RegionInput.deserialize(multipoly),
    minimum_tip_amount,
    minimum_tip_percent,
  };
}

export default DrawerForm;
