import {
  Flex, Input,
  Select,
  Divider,
  Typography,
  Radio,
} from 'antd';
import AddressInput from 'components/AddressInput';
import FormItem from 'components/FormItem';
import React, { useState } from 'react';

function CateringMerchantForm({ merchant, setMerchantDetail }) {
  const [importMenu, setImportMenu] = useState(false);
  const handleSetMerchantDetail = (fieldName, value) => {
    setMerchantDetail((c) => ({ ...c, [fieldName]: value }));
  };

  return (
    <Flex vertical className="box">
      <Divider />
      <Typography.Title level={4}>Merchant info</Typography.Title>
      <FormItem label="Menu">
        <Select
          value={merchant.importMenu}
          onChange={(value) => setImportMenu(value === 'yes')}
          defaultValue="no"
          options={[
            {
              value: 'no',
              label: 'Create menu from scratch',
            },
            {
              value: 'yes',
              label: 'Import from another platform',
            },
          ]}
        />
      </FormItem>

      {
        importMenu ? (
          <>
            <FormItem label="URL">
              <Input
                type="text"
                placeholder="Merchant URL (ex: https://test-merchant.com)"
                value={merchant.url}
                onChange={(e) => handleSetMerchantDetail('url', e.target.value)}
              />
            </FormItem>
            <FormItem label="Email *">
              <Input
                type="email"
                placeholder="email"
                value={merchant.restaurant_email}
                onChange={(e) => handleSetMerchantDetail('restaurant_email', e.target.value)}
              />
            </FormItem>
          </>

        ) : (
          <MainForm type="Partner" merchantDetail={merchant} setMerchantDetail={setMerchantDetail} />
        )
      }
    </Flex>
  );
}
export default CateringMerchantForm;

function MainForm({ type, merchantDetail, setMerchantDetail }) {
  const handleSetMerchantDetail = (fieldName, value) => {
    setMerchantDetail((current) => ({ ...current, [fieldName]: value }));
  };

  const selectAfter = (fieldName) => (
    <Radio.Group
      value={merchantDetail[fieldName]}
      onChange={(e) => handleSetMerchantDetail(fieldName, e.target.value)}
    >
      <Radio.Button value="$">
        $
      </Radio.Button>
      <Radio.Button value="%">%</Radio.Button>
    </Radio.Group>
  );
  const PARTNER_FORM = {
    detail: [
      {
        id: 1,
        value: merchantDetail.name,
        title: 'Name *',
        onChange: (e) => handleSetMerchantDetail('name', e?.target?.value),
        idTitle: 'name',
        placeholder: 'Merchant’s name',
        required: true,
        active: true,
      },
      {
        id: 2,
        value: merchantDetail.email,
        title: 'Email *',
        onChange: (e) => handleSetMerchantDetail('email', e?.target?.value),
        idTitle: 'email',
        placeholder: 'e.g. example@example.com',
        required: true,
        active: true,
        errorMessage: 'email format is not correct',
      },
      {
        id: 3,
        value: merchantDetail.phone,
        title: 'Phone number',
        onChange: (e) => handleSetMerchantDetail('phone', e?.target?.value),
        idTitle: 'phone',
        placeholder: 'Phone number',
        required: true,
        active: true,
      },
    ],
    fees: [
      {
        id: 3,
        value: merchantDetail.markup,
        title: 'Markup',
        onChange: (e) => handleSetMerchantDetail('markup', e?.target?.value),
        idTitle: 'markup',
        placeholder: 'e.g. 15',
        required: false,
        active: true,
        selectAfter,
      },
    ],
  };

  return (
    <>
      {PARTNER_FORM.detail.map((box) => {
        if (!box.active) return null;
        return (
          <FormItem label={box.title}>
            <Input
              type="text"
              placeholder={box.placeholder}
              value={box.value}
              onChange={box.onChange}
              id={box.idTitle}
            />
          </FormItem>
        );
      })}
      <FormItem label="Address *">
        <AddressInput
          value={{
            text: merchantDetail.address,
            lat: merchantDetail.location?.lat,
            lng: merchantDetail.location?.lng,
          }}
          onChange={
            (addr) => {
              handleSetMerchantDetail('address', addr.text);
              handleSetMerchantDetail('location', { lat: addr.lat, lng: addr.lng });
            }
          }
        />
      </FormItem>
    </>
  );
}
