import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Radio, Space } from 'antd';
import BankIcon from 'components/icons/BankIcons';
import StripePaymentModal from 'components/StripePaymentModal';
import { CallInOrderContext } from 'containers/CreateOrder/context';
import React, { useContext, useState } from 'react';
import StripeContainer from './StripeContainer';

function UserCards({
  cards, loading, stripeKey, reFetch,
}) {
  const { order, setOrderDetails } = useContext(CallInOrderContext);

  const [isStripePaymentModalOpen, setIsStripePaymentModalOpen] =
    useState(false);

  return (
    <StripeContainer userId={order.userId} stripeKey={stripeKey}>
      <Radio.Group
        style={{ display: 'block', marginTop: 12 }}
        name="card_id"
        buttonStyle="outline"
        value={order.cardId}
      >
        <Space size={[8, 10]} wrap>
          {cards?.map(({ card, id }) => (
            <Radio.Button
              key={id}
              style={{ background: '#f5f7f8', height: 40 }}
              value={id}
              onChange={(event) => {
                setOrderDetails({ cardId: id });
              }}
            >
              <div style={{
                display: 'inline-flex',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: 'fit-content',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '100%',
              }}
              >
                <BankIcon
                  type={card?.brand}
                  style={{ marginRight: '12px', display: 'flex' }}
                />
                <span style={{ marginRight: 4, textTransform: 'capitalize' }}>
                  {card?.brand}
                </span>
                <span>
                  {' '}
                  ****
                  {card?.last4}
                </span>
              </div>
            </Radio.Button>
          ))}
          {loading && <LoadingOutlined />}

          <Button
            key="add-order"
            icon={<PlusOutlined />}
            onClick={setIsStripePaymentModalOpen}
            type="default"
            style={{ marginLeft: cards.length ? 0 : '10px' }}
          >
            Add
          </Button>
        </Space>
        <StripePaymentModal
          reloadCards={reFetch}
          onClose={() => {
            setIsStripePaymentModalOpen(false);
          }}
          open={isStripePaymentModalOpen}
        />
      </Radio.Group>
    </StripeContainer>
  );
}

export default UserCards;
