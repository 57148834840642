import { Flex, Input, Typography } from 'antd';
import useIsDesktop from 'hooks/useIsDesktop';
import React from 'react';

function CateringUnit({ detail, onChangeDetail }) {
  const isDesktop = useIsDesktop();
  return (
    <Flex vertical={!isDesktop} gap={16}>
      <Flex vertical gap={4}>
        <Typography.Text strong>
          Unit
        </Typography.Text>
        <Typography.Text type="secondary">
          Select the unit of measure for this item or the default will be used.
        </Typography.Text>
        <Input
          value={detail.cateringLabel}
          placeholder="e.g. Box"
          onChange={(e) => onChangeDetail('cateringLabel', e.target.value)}
        />
      </Flex>

      <Flex vertical gap={4}>
        <Typography.Text strong>
          Servings per unit
        </Typography.Text>
        <Typography.Text type="secondary">
          Specify the number of people served by one unit of this item.
        </Typography.Text>
        <Input
          defaultValue={1}
          value={detail.cateringUnitPerLabel}
          onChange={(e) => onChangeDetail('cateringUnitPerLabel', e.target.value)}
          suffix={detail.cateringUnitPerLabel > 1 ? 'People' : 'Person'}
        />
      </Flex>

    </Flex>
  );
}
export default CateringUnit;
