import { generateQuery } from 'utils';
import http from 'services/http';

export const getAllCustomers = (searchKey, page, status) => {
  const query = generateQuery([
    searchKey ? `search=${searchKey}` : '',
    page > 1 ? `page=${page}` : '',
    status ? `status=${status}` : '',
  ]);
  return http
    .get(`v1/organizations/{{organizationId}}/customers/${query}`)
    .then((res) => res.data);
};
export const fetchCustomers = ({ searchKey, page = 1, status }) => {
  const query = generateQuery([
    searchKey ? `search=${searchKey}` : '',
    page > 1 ? `page=${page}` : '',
    status ? `status=${status}` : '',
  ]);
  return http
    .get(`v1/organizations/{{organizationId}}/customers/${query}`)
    .then((res) => res.data);
};

export const getCustomerById = (customerId) => http
  .get(`v1/organizations/{{organizationId}}/customers/${customerId}`)
  .then((res) => res.data);

export const getCustomerOrdersById = (customerId, page) => {
  const query = generateQuery([page > 1 ? `page=${page}` : '']);
  return http
    .get(
      `v1/organizations/{{organizationId}}/customers/${customerId}/orders/${query}`,
    )
    .then((res) => res.data);
};
export const addAddressToCustomer =
  ({
    title,
    address,
    latitude,
    longitude,
    details,
    delivery_instructions,
    customer,
  }) => {
    const payLoad = {
      latitude,
      longitude,
      details,
      delivery_instructions,
      address,
      customer,
    };
    if (title) payLoad.title = title.slice(0, 50);
    return http
      .post(
        `v1/organizations/{{organizationId}}/customers/${customer}/addresses/`,
        payLoad,
      )
      .then((res) => res.data);
  };
export const createCustomer =
  ({ name, phoneNumber, email }) => {
    const payLoad = {
      name,
      phone: phoneNumber,
      email,
    };
    if (name) payLoad.name = name.slice(0, 50);
    return http
      .post('v1/organizations/{{organizationId}}/customers/', payLoad)
      .then((res) => res.data);
  };
