import { EllipsisOutlined } from '@ant-design/icons';
import {
  Dropdown, Flex, Space, Switch, Tag, Tooltip,
} from 'antd';
import { TAGS } from '../../components/DietaryTags';
import React from 'react';

const columns = ({ onChangeAvailability, onEditCategory, onRemoveCategory }) => ([
  {
    title: 'Name', key: 'title', dataIndex: 'title', width: 250,
  },
  {
    title: 'Options',
    key: 'options',
    dataIndex: 'options',
    render: (value, record) => {
      const options = record.options.map((option) => (
        <Tag key={option.id} color={option.modifiers_children?.length ? 'green' : ''}>
          <Flex gap={2} align="center">
            {option.title}
            {option.modifiers_children?.length > 0 && ' (nested)'}
            <Flex>
              {option.dietary_tags?.map( (option) => TAGS[option]?.icon)}
            </Flex>
          </Flex>

        </Tag>
      ));
      return (
        <Space size={[0, 8]} wrap>
          {options.slice(0, 5)}
          {options.length > 5 && (
            <Tooltip
              title={options.length > 5 ? <Space size={[0, 4]} wrap>{options}</Space> : ''}
              color="white"
              placement="bottomRight"
              overlayStyle={{ maxWidth: '600px' }}
            >
              <Tag bordered={false}><EllipsisOutlined /></Tag>
            </Tooltip>
          )}
        </Space>
      );
    },
  },
  {
    title: 'Availability',
    key: 'is_active',
    dataIndex: 'is_active',
    width: 110,
    align: 'center',
    render: (value, record) => (
      <Switch checked={value} onChange={() => onChangeAvailability(record)} />
    ),
  },
  {
    key: 'action',
    width: 50,
    align: 'center',
    render: (value, data) => (
      <Dropdown menu={{
        items: [
          {
            key: '1',
            label: <a type="text" onClick={(e) => onEditCategory(e, data)}>Edit</a>,
          },
          {
            key: '2',
            label: <a type="text" onClick={(e) => onRemoveCategory(e, data)}>Remove</a>,
          },
        ],
      }}
      >
        <EllipsisOutlined />
      </Dropdown>
    ),
  },
]);
export default columns;
