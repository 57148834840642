import { Button, Modal } from 'antd';
import React from 'react';

function SimpleModal(
  {
    children,
    open,
    setOpen,
    loading = false,
    title,
    footer,
    onSubmit,
    onCancel,
    width = 570,
    submitProps,
  },
) {
  const handleCancel = () => {
    onCancel && onCancel();
    setOpen(false);
  };

  const handleOk = () => {
    onSubmit();
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      title={title}
      width={width}
      onOk={handleOk}
      centered
      onCancel={handleCancel}
      footer={footer || [
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleOk} {...submitProps}>
          Confirm
        </Button>,
      ]}
    >
      {children}
    </Modal>
  );
}

export default SimpleModal;
