import React from 'react';

function DrinkIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.15" x="1.33334" y="1.3335" width="13.3333" height="13.3333" rx="4" fill="black" fill-opacity="0.45"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.27569 4.21521C5.33179 3.8388 5.6549 3.56006 6.03577 3.56006H10.456C10.8369 3.56006 11.16 3.8388 11.2161 4.21521L11.5419 6.40802C11.862 8.32449 10.4899 10.0805 8.60598 10.2811L8.60622 12.2093H9.2985C9.49753 12.2093 9.65888 12.3707 9.65888 12.5697C9.65888 12.7688 9.49753 12.9301 9.2985 12.9301H8.25689L8.24593 12.9303H8.24036L8.23488 12.9301H7.19336C6.99432 12.9301 6.83297 12.7688 6.83297 12.5697C6.83297 12.3707 6.99432 12.2093 7.19336 12.2093H7.88545L7.88521 10.2811C6.00134 10.0804 4.6298 8.32439 4.94989 6.40802L5.27569 4.21521ZM5.98859 4.32146L5.66234 6.51724L5.66127 6.52401C5.65454 6.56406 5.64875 6.60402 5.64388 6.64388L5.79415 6.55196C6.62873 6.04249 7.67052 6.00808 8.53665 6.46184C9.19399 6.80628 9.98539 6.77105 10.6091 6.36948L10.7902 6.25309L10.5032 4.32146C10.4997 4.29808 10.4797 4.28083 10.456 4.28083H6.03577C6.01205 4.28083 5.99208 4.29809 5.98859 4.32146Z" fill="black" fill-opacity="0.45"/>
    </svg>
  );
}
export default DrinkIcon;
