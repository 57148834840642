import {
  DeleteOutlined, MinusOutlined, PlusOutlined,
} from '@ant-design/icons';
import {
  Radio,
  Typography,
} from 'antd';
import React from 'react';

function QuantityInput({
  value: qty,
  max,
  min = 0,
  disabled,
  onChange,
  className,
  useTrashIcon = false,
}) {
  const minDisabled = disabled || qty <= min;

  return (
    <Radio.Group
      size="small"
      value="nothing"
      className={`${className || ''} whitespace-nowrap`}
    >
      <Radio.Button
        disabled={minDisabled}
        onClick={() => onChange(qty - 1)}
      >
        {qty === 1 && useTrashIcon ? <DeleteOutlined /> : <MinusOutlined />}
      </Radio.Button>
      <Radio.Button disabled>
        <Typography.Text className="font-mono">{qty}</Typography.Text>
      </Radio.Button>
      <Radio.Button
        disabled={disabled || qty >= max}
        onClick={() => onChange(qty + 1)}
      >
        <PlusOutlined />
      </Radio.Button>
    </Radio.Group>
  );
}

export default QuantityInput;
