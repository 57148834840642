import { NewOrdersContext } from 'constants/contexts';
import React, { useContext, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Badge, Menu } from 'antd';
import { useHasAnyOfPermissionsCallback } from 'hooks/useHasPermission';
import { SIDEBAR_ITEMS } from 'constants/sidebar-items';

function useSidebarItems() {
  const hasAccess = useHasAnyOfPermissionsCallback();

  return useMemo(() => {
    const topItems = generateSidebarItems(SIDEBAR_ITEMS.filter((i) => !i.bottom));
    const bottomItems = generateSidebarItems(SIDEBAR_ITEMS.filter((i) => i.bottom));

    console.log({ topItems, bottomItems, SIDEBAR_ITEMS });
    return { topItems, bottomItems };

    function generateSidebarItems(items) {
      return items
        .filter((n) => hasAccess(n.permissions))
        .map((s) => (
          <Menu.Item key={s.label} icon={s.icon}>
            {s.route?.path ? (
              <NavLink to={s.route.path.replace(/\/:[a-zA-Z]*\?/g, '')} exact={s.route.path === '/console'} key={s.key}>
                {s.label}
                <NavBadge route={s.route} badge={s.badge} />
              </NavLink>
            ) : s.label}
          </Menu.Item>
        ));
    }
  }, [hasAccess]);
}

function NavBadge({ badge, route }) {
  const { newOrderIds, newScheduledOrderIds } = useContext(NewOrdersContext);
  const newOrdersCount = new Set([...newOrderIds, ...newScheduledOrderIds]).size;
  const location = useLocation();
  const badges = {
    orders: () => {
      if (newOrdersCount === 0) {
        return null;
      }
      return newOrdersCount > 9 ? '+9' : newOrdersCount;
    },
  };
  if (!badge || !badges?.[badge]() || location.pathname.includes(route.path)) {
    return null;
  }
  return <Badge count={badges[badge]()} className="ml-4" />;
}

export default useSidebarItems;
