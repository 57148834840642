import http from 'services/http';
import { generateURLWithParams } from 'utils';

export const fetchMenusByMerchantId = (merchantId) => http.get(generateURLWithParams(`v1/restaurants/${merchantId}/menus/`, {
  query: '{id,name,position,from_hour,duration,weekdays,is_active,order_type,active_hours}',
})).then((res) => res.data);

export const fetchMenusById = (menuId) => http.get(generateURLWithParams(`v1/menus/${menuId}/`, {
  query: '{id,active_hours,order_type,description,name,images,from_hour,duration,weekdays,is_active,categories{id,name,featured,description,position,items},are_item_images_visible,placeholder_image}',
})).then((res) => res.data);
