import React from 'react';

function VegetarianIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.15" x="1.33331" y="1.3335" width="13.3333" height="13.3333" rx="4" fill="black" fill-opacity="0.45"/>
      <path d="M10.6311 11C10.2685 11 9.92313 10.936 9.59513 10.808C9.26713 10.6773 8.97647 10.4853 8.72313 10.232C8.47247 9.97867 8.2738 9.66533 8.12713 9.292C7.98313 8.91867 7.91113 8.488 7.91113 8C7.91113 7.36267 8.03247 6.82133 8.27513 6.376C8.52047 5.928 8.84847 5.58667 9.25913 5.352C9.67247 5.11733 10.1298 5 10.6311 5C11.3725 5 11.9551 5.172 12.3791 5.516C12.8031 5.86 13.0871 6.34667 13.2311 6.976L12.1191 7.136C12.0151 6.8 11.8445 6.532 11.6071 6.332C11.3725 6.12933 11.0711 6.02667 10.7031 6.024C10.3378 6.01867 10.0338 6.09733 9.79113 6.26C9.54847 6.42267 9.3658 6.65333 9.24313 6.952C9.12313 7.25067 9.06313 7.6 9.06313 8C9.06313 8.4 9.12313 8.74667 9.24313 9.04C9.3658 9.33333 9.54847 9.56133 9.79113 9.724C10.0338 9.88667 10.3378 9.97067 10.7031 9.976C10.9511 9.98133 11.1751 9.93867 11.3751 9.848C11.5778 9.75733 11.7485 9.61467 11.8871 9.42C12.0258 9.22533 12.1245 8.976 12.1831 8.672H11.2711V7.832H13.3431C13.3485 7.86667 13.3525 7.928 13.3551 8.016C13.3578 8.104 13.3591 8.15467 13.3591 8.168C13.3591 8.72 13.2471 9.20933 13.0231 9.636C12.7991 10.0627 12.4831 10.3973 12.0751 10.64C11.6671 10.88 11.1858 11 10.6311 11Z" fill="black" fill-opacity="0.45"/>
      <path d="M4.40081 10.8801L2.64081 5.12012H3.76081L5.20081 9.85612L6.66481 5.12012H7.78481L6.02481 10.8801H4.40081Z" fill="black" fill-opacity="0.45"/>
    </svg>

  );
}
export default VegetarianIcon;
