import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import ItemPrice from '../AddOrderItemOverlay/ItemPrice';
import { hasOptionChildren } from './utils';

function OptionRow({ option, children }) {
  const hasChildren = hasOptionChildren(option);
  return (
    <div className="flex align-items-center justify-content-center flex-grow-1">
      {children}
      <Space align="center" style={{ justifyContent: 'space-between', width: '100%' }}>
        <Typography.Text>
          {option?.title}
        </Typography.Text>
        <div className="flex justify-content-end" style={{ gap: 4 }}>
          <ItemPrice price={option.price} discountedPrice={option.discountedPrice} showZero />
          {hasChildren && <RightOutlined style={{ fontSize: 12 }} />}
        </div>
      </Space>
    </div>
  );
}

export default OptionRow;
