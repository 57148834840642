import { AimOutlined, EnvironmentFilled, EnvironmentOutlined } from '@ant-design/icons';
import { AutoComplete, Input, Skeleton } from 'antd';
import { reverseGeoCode } from 'api/geocode';
import useAutoComplete from 'hooks/useAutoComplete';
import React, { useEffect, useState } from 'react';
import {
  GoogleMap, useJsApiLoader, Marker,
} from '@react-google-maps/api';
import useDebouncedEffect from 'use-debounced-effect';
import { captureException } from 'utils/errors';
import { getGeoCode } from 'utils/places';

function normalizeLatLng(latLng) {
  if (!latLng) return {};
  return {
    lat: typeof latLng.lat === 'function' ? latLng.lat() : latLng.lat,
    lng: typeof latLng.lng === 'function' ? latLng.lng() : latLng.lng,
  };
}

function BaseMap({
  children,
  onChange = () => {
  },
  center: defaultCenter,
  height = 300,
  ...props
}) {
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState(defaultCenter);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBiGU97-Nd44zat6xJvd93Na6l2m61Y-Zw',
    libraries: ['places', 'drawing'],
  });

  useDebouncedEffect(() => {
    reverseGeoCode(normalizeLatLng(center))
      .then((text) => {
        console.log('text', text);
        onChange({ ...normalizeLatLng(center), text });
      })
      .catch((e) => {
        captureException(e);
        onChange(normalizeLatLng(center));
      });
  }, 500, [center]);

  useEffect(() => {
    setCenter({
      lat: defaultCenter?.lat,
      lng: defaultCenter?.lng,
    });
  }, [defaultCenter?.lat, defaultCenter?.lng]);

  const onLoad = React.useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback((map) => {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        height,
        width: '100%',
      }}
      center={center}
      zoom={15}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={(e) => setCenter(e.latLng)}
      onCenterChanged={() => {
        if (!map) {
          return;
        }
        setCenter(map.getCenter());
      }}
      options={{
        streetViewControl: false,
        mapTypeControl: false,
        gestureHandling: 'greedy',
        styles: [
          {
            featureType: 'poi',
            stylers: [
              { visibility: 'off' },
            ],
          },
        ],
        ...props,
      }}
    >
      {children}
    </GoogleMap>
  ) : <MapSkeleton height={height} />;
}

const Map = React.memo(BaseMap);

Map.FixedMarker = function (props) {
  return (
    <EnvironmentFilled
      {...props}
      style={{
        position: 'absolute',
        left: 'calc( 50% - 15px)',
        top: 'calc( 50% - 15px)',
        fontSize: 32,
        cursor: 'grab',
        ...props.style,
      }}
    />
  );
};

Map.Marker = Marker;

Map.SearchInput = function SearchInput({ onChange, value: defaultAddress, ...props }) {
  const [value, setValue] = useState('');
  const options = useAutoComplete(value);
  async function handleSelect(_, selectedOption) {
    if (!selectedOption || typeof selectedOption !== 'object') {
      return;
    }
    const {
      place_id: placeId,
      description: text,
    } = selectedOption;
    const { lat, lng } = await getGeoCode(placeId);
    onChange({ lat, lng, text });
  }

  useEffect(() => {
    if (defaultAddress?.place_id) handleSelect('', defaultAddress);
  }, [defaultAddress]);

  const handleCurrentLocation = async () => {
    if (navigator?.geolocation) {
      await navigator.geolocation.getCurrentPosition(success, fail);
    } else {
      alert('You should set location permission');
    }

    function success(position) {
      const { coords: { latitude: lat, longitude: lng } } = position;
      onChange({ lat, lng });
    }

    function fail() {
      alert('You should set location permission!');
    }
  };

  return (
    <AutoComplete
      options={options}
      style={{ width: '85%', top: 10, left: 8 }}
      onSelect={handleSelect}
      onSearch={setValue}
    >
      <Input
        prefix={<EnvironmentOutlined />}
        addonAfter={<AimOutlined onClick={handleCurrentLocation} />}
        placeholder="Enter the delivery address"
        size="large"
      />
    </AutoComplete>
  );
};

function MapSkeleton({ height }) {
  return (
    <Skeleton.Input block fullSize active style={{ height }} />
  );
}

export default Map;
