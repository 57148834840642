import { Tabs } from 'antd';
import Layout from 'components/Layout';
import React, { useMemo } from 'react';
import {
  Route, Switch, useHistory, useParams,
} from 'react-router-dom';
import withGuard from 'components/withGuard';
import { PERMISSIONS } from 'constants/permissions';
import AdjustBalance from './AdjustBalance';
import CreditHistory from './CreditCardHistory';
import GiftCardHistory from './GiftCardHistory';
import Overview from './Overview';
import { StyledWrapper } from './styles';

const ROUTES = [
  {
    label: 'Overview',
    path: '/',
    Component: Overview,
    key: 1,
  },
  {
    label: 'Credit history',
    path: '/credit-history',
    Component: CreditHistory,
    key: 2,
  },
  {
    label: 'Gift card details',
    path: '/gift-card',
    Component: GiftCardHistory,
    key: 3,
  },
  {
    label: 'Adjust Balance',
    path: '/adjust-balance',
    Component: AdjustBalance,
    key: 4,
  },
  {
    title: 'Overview',
    path: '/orders/:orderId',
    Component: Overview,
    id: 6,
  },
];
function CustomerDetail() {
  const history = useHistory();
  const { id: customerId, section } = useParams();
  const activeTab = useMemo(
    () => ROUTES.find((r) => r.path.substring(1) === section) || ROUTES[0],
    [section],
  );

  return (
    <Layout header={(
      <Layout.Header
        backTitle="Customers"
        backPath="/console/customers"
      />
    )}
    >
      <StyledWrapper>
        <Tabs
          items={ROUTES}
          defaultActiveKey={ROUTES[0].key}
          onChange={(key) => history.push(`/console/customers/${customerId}${ROUTES[key - 1].path}`)}
        />

        <div>
          <Switch>
            {ROUTES.map(({ path, Component }) => (
              <Route key={path} path={`/console/customers/:id${path}`} exact>
                <Component />
              </Route>
            ))}
          </Switch>
        </div>

      </StyledWrapper>
    </Layout>
  );
}

export default withGuard(CustomerDetail, [PERMISSIONS.CUSTOMERS], { redirect: true });
