import { Flex, Select, Typography } from 'antd';
import React from 'react';
import { TAGS } from './index';

const { Option } = Select;

function Tags({ detail, onChangeDetail, maxTagCount = 4 }) {
  return (
    <Flex vertical gap={8}>
      <Typography.Text strong>
        Tag(s)
        <Typography.Text type="secondary"> (optional)</Typography.Text>
      </Typography.Text>
      <Typography.Text type="secondary">
        Select relevant tags for this item to be displayed to customers, such as
        Vegetarian or Gluten-Free.
      </Typography.Text>
      <TagSelector
        value={detail?.dietaryTags}
        onChangeDetail={(values) => onChangeDetail('dietaryTags', values)}
        maxTagCount={maxTagCount}
      />
    </Flex>
  );
}

const getOptions = () => {
  const options = [];
  for (const tag in TAGS) {
    options.push(<Option key={tag} >
      <Flex align="center" gap={3}>
        {TAGS[tag].icon}
        {TAGS[tag].label}
      </Flex>
    </Option>);
  }
  return options;
};
export function TagSelector({
  defaultValue, value, onChangeDetail, maxTagCount,
}) {
  return (
    <Select
      className="multi-select-filter-box"
      mode="multiple"
      allowClear
      style={{ width: '100%' }}
      placeholder="Select"
      onChange={onChangeDetail}
      defaultValue={defaultValue}
      value={value}
      maxTagCount={maxTagCount}
      filterOption={(inputValue, option) => (typeof option.children === 'string' ? option.children : option.children.join('')).toLowerCase().includes(inputValue.toLowerCase())}
    >
      {getOptions()}
    </Select>
  );
}
export default Tags;
