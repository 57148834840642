import { PlusOutlined } from '@ant-design/icons';
import InboxOutlined from '@ant-design/icons/InboxOutlined';
import {
  Button,
  Flex, Input, List, Result,
  Skeleton, Space, Table, Typography,
} from 'antd';
import AmountInput from 'components/AmountInput';
import React, {
  useMemo,
  useState,
} from 'react';
import { priceTransformer } from 'utils';
import { generateColumns } from 'utils/table';
import AddOrderItemOverlay from './AddOrderItemOverlay';
import ItemDetailOverlay from './ItemDetailOverlay';
import { ItemsInputContext } from './constants';
import QuantityInput from '../../../../components/QuantityInput';

const { TextArea } = Input;

function ItemsInput({
  value: orderItems, onChange, merchantId, loading,
  validating,
}) {
  const [showAddItem, setShowAddItem] = useState(false);
  const [selectedOrderItem, setSelectedOrderItem] = useState();

  const handleRemoveOrderItem = (orderItemId) => {
    onChange(orderItems.filter(({ id }) => id !== orderItemId));
  };

  function handleRemoveOrderOption({ orderItemId, orderOptionId }) {
    onChange(orderItems.map((orderItem) => {
      if (orderItem.id === orderItemId) {
        return {
          ...orderItem,
          order_options: orderItem.order_options.filter((or) => or.id !== orderOptionId),
        };
      }
      return orderItem;
    }));
  }

  const normalizedColumns = useMemo(() => generateColumns([
    {
      title: 'name',
      key: 'item_related.name',
      width: 300,
      render: (_, { item_related, size }) => (
        <div>
          {item_related.image && (
            <img
              className="image"
              src={item_related.image}
              alt={item_related.name || ''}
              width={45}
              height={45}
              style={{ border: '1px solid #DDE1E4', borderRadius: 5, marginRight: 4 }}
            />
          )}

          {item_related.name || ' - '}
          {' '}
          {size}
        </div>
      ),
    },
    {
      title: 'base price',
      key: 'real_price',
      dataIndex: 'real_price',
      width: 100,
      render: (value, orderItem) => (
        <Flex vertical>
          <AmountInput
            value={value}
            onChange={(real_price) => {
              if (real_price === 0 || real_price) {
                onChange(orderItems.map((i) => (i.id === orderItem.id ? { ...i, real_price } : i)));
              }
            }}
            size="small"
          />
          <Typography.Text
            type="secondary"
            style={{
              marginBottom: -15,
              fontSize: 10,
              whiteSpace: 'nowrap',
            }}
          >
            Without markup
          </Typography.Text>
        </Flex>
      )
      ,
    },
    {
      title: '',
      key: 'x',
      width: 30,
      render: () => 'x',
    },
    {
      title: 'quantity',
      key: 'qty',
      width: 150,
      render: (_, orderItem) => (
        <Flex gap={8} align="center">
          <QuantityInput
            min={1}
            value={orderItem.qty || 0}
            onChange={(qty) => {
              onChange(orderItems.map((i) => (i.id === orderItem.id ? { ...i, qty } : i)));
            }}
          />
          <Space>
            <span>=</span>
            {validating ? <Skeleton.Button active /> : <span>{orderItem.total_value}</span>}
          </Space>

        </Flex>
      ),
    },
    {
      title: (
        <div style={{ textAlign: 'right' }}>
          <Button
            icon={<PlusOutlined />}
            type="default"
            size="large"
            onClick={() => setShowAddItem(true)}
          >
            Add item
          </Button>
        </div>
      ),
      // width: '3%',
      render: (_, data) => (
        <Flex style={{ justifyContent: 'right' }}>
          <Button type="text" onClick={() => setSelectedOrderItem(data)}>Edit</Button>
          <Button type="text" danger onClick={() => handleRemoveOrderItem(data.id)}>Remove</Button>
        </Flex>
      ),
    },
  ]), [validating, orderItems]);

  const renderRowDetail = (orderItem, index) => (
    <Flex
      vertical
      gap={8}
      className="px-6"
      style={{ backgroundColor: '#E6F3EB' }}
    >
      <List
        itemLayout="horizontal"
        dataSource={orderItem.order_options}
        renderItem={(op) => (
          <Flex className="py-2 ml-10" style={{ borderBottom: '1px solid #e9e9e9' }}>
            <Typography.Text style={{ width: 300 }}>
              {op.qty ? `${op.qty} x ` : ''}
              {op.modifier_option.title}
            </Typography.Text>
            <Typography.Text>{priceTransformer(op.markup_price)}</Typography.Text>
            {
              !op.modifier_option.modifier.mandatory && (
                <Button
                  type="text"
                  size="small"
                  danger
                  onClick={() => {
                    handleRemoveOrderOption({ orderItemId: orderItem.id, orderOptionId: op.id });
                  }}
                  style={{ marginLeft: 'auto' }}
                >
                  Remove
                </Button>
              )
            }
          </Flex>
        )}
      />
      <TextArea
        value={orderItem.message}
        onChange={(e) => {
          onChange(orderItems.map((i) => (i.id === orderItem.id ? { ...i, message: e.target.value } : i)));
        }}
        rows={2}
        placeholder="Special notes"
        maxLength={100}
        style={{ maxWidth: 400 }}
        className="my-2 ml-10"
      />
    </Flex>
  );

  const contextValue = useMemo(() => ({
    selectedOrderItem,
  }), [selectedOrderItem]);

  if (loading) {
    return <Skeleton paragraph={{ rows: 3 }} />;
  }
  return (
    <ItemsInputContext.Provider value={contextValue}>
      <Table
        columns={normalizedColumns}
        dataSource={orderItems}
        pagination={false}
        rowKey="id"
        expandable={{
          expandedRowRender: renderRowDetail,
        }}
        style={{ marginTop: 16 }}
        locale={{
          emptyText: (<Result
            style={{
              marginTop: 20,
              filter: 'grayscale(1)',
              opacity: 0.3,
            }}
            icon={<InboxOutlined />}
            title="No data"
          />),
        }}
        scroll={{ x: 700 }}
      />
      <AddOrderItemOverlay
        open={showAddItem}
        merchantId={merchantId}
        onClose={() => setShowAddItem(false)}
        onAdd={setSelectedOrderItem}
      />
      <ItemDetailOverlay
        nested={showAddItem}
        onChange={(newOrderItem) => {
          if (orderItems.some((oi) => oi.id === newOrderItem.id)) {
            onChange(orderItems.map((o) => (o.id !== newOrderItem.id ? o : newOrderItem)));
          } else {
            onChange([...orderItems, newOrderItem]);
          }
          setTimeout(() => setShowAddItem(false), 300);
        }}
        onClose={() => setSelectedOrderItem(null)}
        onSubmit={() => setTimeout(() => setShowAddItem(false), 500)}
        handleAddItem={(item) => {
          onChange(item);
        }}
      />
    </ItemsInputContext.Provider>

  );
}

export default ItemsInput;
