import Layout from 'components/Layout';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button, Col, Popover, Row, Switch, Tag,
} from 'antd';
import { getData } from 'utils';
import { getMerchantById, updateMerchant } from 'api/merchants';
import { ArrowUpOutlined } from '@ant-design/icons';
import { MERCHANT_PANEL_URL, MERCHANT_TYPE } from 'constants';
import { captureException } from 'utils/errors';
import { PERMISSIONS } from 'constants/permissions';
import withGuard from 'components/withGuard';
import { useHasAnyOfPermissions } from 'hooks/useHasPermission';
import { StyledWrapper } from './styles';
import RightBox from './RightBox';
import MerchantDetailsBox from './MerchantDetailsBox';
import FreeStructureBox from './FeeStructureBox';
import AvailableFeaturesBox from './AvailableFeaturesBox';
import { useInputState } from '../../hooks/useInputState';

function MerchantDetail() {
  const [merchantDataLoading, setMerchantDataLoading] = useState(false);
  const [pickupLoading, setPickupLoading] = useState(false);
  const [togglePickup, setTogglePickup] = useState(false);
  const [deliveryLoading, setDeliveryLoading] = useState(false);
  const [partnerLoading, setPartnerLoading] = useState(false);
  const [toggleDelivery, setToggleDelivery] = useState(false);
  const [togglePartner, setTogglePartner] = useState(false);
  const orderApproval = useInputState(false);
  const disableMerchant = useInputState(false);
  const [merchantData, setMerchantData] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const isAdmin = useHasAnyOfPermissions([PERMISSIONS.ADMIN_ACCESS]);
  // const isExternalMerchant = merchantData?.migration_id;
  const isExternalMerchant = false;

  useEffect(() => {
    setToggles(merchantData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantData]);

  const setToggles = (merchant) => {
    setTogglePickup(merchant?.has_pickup);
    setToggleDelivery(merchant?.has_delivery);
    setTogglePartner(merchant?.is_partner);
    orderApproval.setValue(merchant?.needs_order_approval);
    disableMerchant.setValue(merchant?.is_disabled);
  };

  const handleTogglePickup = () => {
    setPickupLoading(true);
    updateMerchant(
      merchantData?.id,
      {
        has_pickup: !togglePickup,
      },
    )
      .then((res) => {
        setPickupLoading(false);
        setTogglePickup(getData(res)?.has_pickup);
      })
      .catch((e) => {
        captureException(e);
        setPickupLoading(false);
      });
  };

  const handleToggleDelivery = () => {
    setDeliveryLoading(true);
    updateMerchant(
      merchantData?.id,
      {
        has_delivery: !toggleDelivery,
      },
    )
      .then((res) => {
        setDeliveryLoading(false);
        setToggleDelivery(getData(res)?.has_delivery);
      })
      .catch((e) => {
        captureException(e);
        setDeliveryLoading(false);
      });
  };

  const handleTogglePartner = () => {
    setPartnerLoading(true);
    updateMerchant(
      merchantData?.id,
      {
        is_partner: !togglePartner,
      },
    )
      .then((res) => {
        const updatedMerchant = getData(res);
        setMerchantData(updatedMerchant);
        setTogglePartner(updatedMerchant?.is_partner);
      })
      .finally(() => setPartnerLoading(false));
  };

  const handleToggleDisable = () => {
    disableMerchant.startLoading();
    updateMerchant(
      merchantData?.id,
      {
        is_disabled: !disableMerchant.value,
      },
    )
      .then((res) => disableMerchant.setValue(getData(res)?.is_disabled))
      .catch((e) => {
        captureException(e);
        disableMerchant.stopLoading();
      });
  };

  const handleToggleOrderApproval = () => {
    orderApproval.startLoading();
    updateMerchant(
      merchantData?.id,
      {
        needs_order_approval: !orderApproval.value,
      },
    )
      .then((res) => orderApproval.setValue(getData(res)?.needs_order_approval))
      .catch((e) => {
        captureException(e);
        orderApproval.stopLoading();
      });
  };

  // Todo: move this to a separate component
  const actions = [
    isAdmin && <div className="cursor-pointer" onClick={() => history.push(`/console/merchants/edit/${id}`)}>Edit merchant</div>,
    merchantData.merchant_type !== MERCHANT_TYPE.NP_OMNI_MERCHANT && (
      <>
        <span>Pick up</span>
        {' '}
        <Switch
          size="small"
          checked={togglePickup}
          onChange={pickupLoading ? null : handleTogglePickup}
          disabled={!merchantData.is_partner}
          loading={pickupLoading}
        />
      </>
    ),
    <>
      <span>Delivery</span>
      {' '}
      <Switch
        size="small"
        checked={toggleDelivery}
        onChange={deliveryLoading ? null : handleToggleDelivery}
        loading={deliveryLoading}
      />
    </>,
    merchantData.merchant_type === MERCHANT_TYPE.OMNI_MERCHANT && (
      <>
        <span>Partner</span>
        {' '}

        <Switch
          size="small"
          loading={partnerLoading}
          checked={togglePartner}
          onChange={partnerLoading ? null : handleTogglePartner}
        />
      </>
    ),
    merchantData.is_partner && isAdmin && (
      <>
        <span>Order pre-approval</span>
        {' '}
        <Switch
          size="small"
          checked={orderApproval.value}
          loading={orderApproval.isLoading}
          onChange={
            orderApproval.isLoading ? null : handleToggleOrderApproval
          }
        />
      </>
    ),
    isAdmin && (
      <>
        <span>Disable</span>
        {' '}

        <Switch
          size="small"
          loading={disableMerchant.isLoading}
          checked={disableMerchant.value}
          onChange={disableMerchant.isLoading ? null : handleToggleDisable}
        />
      </>
    ),

  ].filter(Boolean);

  useEffect(() => {
    setMerchantDataLoading(true);
    getMerchantById(id)
      .then((res) => {
        const data = getData(res);
        setMerchantData(data);
        setMerchantDataLoading(false);
        setToggles(data);
      })
      .catch((e) => {
        captureException(e);
        setMerchantDataLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStatus = (needAction) => {
    const statusTitle = needAction ? 'Need action' : 'Completed';
    return (
      <Tag color={needAction ? 'red' : 'green'}>
        {statusTitle}
      </Tag>
    );
  };

  return (
    <Layout header={(
      <Layout.Header
        title={merchantData?.name}
        tag={handleStatus(merchantData?.need_actions)}
        loading={merchantDataLoading}
        backTitle="Merchants"
        backPath="/console/merchants"
        extra={[
          merchantData.is_catering && (
            <Button
              icon={<ArrowUpOutlined className="rotate-45" />}
              href={`https://caterlocally.com/pwa/catering/${merchantData.id}/${merchantData.slug}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Website
            </Button>
          ),
          <Button href={`/console/merchants/${merchantData.id}/menus`}>
            Menu management
          </Button>,
          <Button
            href={`${MERCHANT_PANEL_URL}?merchantId=${merchantData.id}`}
            target="blank"
            icon={<ArrowUpOutlined className="rotate-45" />}
          >
            Merchant panel
          </Button>,
          <Popover
            placement="bottomRight"
            content={(
              actions.map((action) => (
                <div className="flex flex-row justify-between gap-3 my-2">
                  {action}
                </div>
              ))
            )}
          >
            <Button>Actions</Button>
          </Popover>,
        ].filter(Boolean)}
      />
    )}
    >
      <StyledWrapper>
        <Row gutter={[8, 8]}>
          <Col xs={24} lg={8} xl={5}>
            <div className="flex flex-column flex-md-row flex-lg-column gap-4">
              <MerchantDetailsBox
                merchantData={merchantData}
                merchantDataLoading={merchantDataLoading}
              />
              <div>
                <FreeStructureBox
                  merchantData={merchantData}
                  merchantDataLoading={merchantDataLoading}
                />
                {!isExternalMerchant && (
                  <AvailableFeaturesBox
                    merchantData={merchantData}
                    merchantDataLoading={merchantDataLoading}
                  />
                )}
              </div>
            </div>
          </Col>
          <Col xs={24} lg={16} xl={19}>
            <RightBox
              merchantData={merchantData}
              merchantDataLoading={merchantDataLoading}
            />
          </Col>
        </Row>
      </StyledWrapper>
    </Layout>
  );
}

export default withGuard(MerchantDetail, [PERMISSIONS.MERCHANTS], { redirect: true });
