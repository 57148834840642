import {
  Button, Flex, Form as AntForm, Select,
  App,
} from 'antd';
import DebounceSelect from 'components/Select/DebounceSelect';
import useConfig from 'hooks/useConfig';
import useIsMobile from 'hooks/useIsMobile';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { captureException } from 'utils/errors';
import { getHttpErrorMessage } from 'services/http';
import { addNewMerchant, addNewOmniMerchant } from '../requests';
import OmniMerchantForm from './OmniMerchantForm';
import MainForm from './MainForm';
import { getParents } from '../../../api/merchants';
import { StyledWrapper } from './styles';
import CateringMerchantForm from './CateringMerchantForm';
import Results from './Results';

// TODO: reimplement this form using the AntForm
function Form() {
  const general = useConfig('general');
  const DEFAULT_MERCHANT_DATA = {
    email: '',
    name: '',
    phone: '',
    address: '',
    location: { lat: general?.defaultLat, lng: general?.defaultLong, text: '' },
    deliveryCommission: null,
    deliveryCommissionType: '%',
    pickupCommission: null,
    pickupCommissionType: '%',
    markup: null,
    markupType: '%',
    gift: false,
    schedule: false,
  };
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [type, setType] = useState('Non-partner');
  const isMobile = useIsMobile();
  const [parentId, setParentId] = useState();
  const { message } = App.useApp();
  const [merchant, setMerchant] = useState(DEFAULT_MERCHANT_DATA);

  useEffect(() => {
    if (general?.defaultLat) {
      setMerchant((m) => ({ ...m, location: { lat: general.defaultLat, lng: general?.defaultLong, text: '' } }));
    }
  }, [general?.defaultLat]);
  const history = useHistory();

  const clearForm = () => {
    setParentId();
    setMerchant(DEFAULT_MERCHANT_DATA);
  };
  const handleSave = (continueAdding) => {
    const isCatering = type === 'Catering';
    if (isCatering && !merchant.email && !merchant.restaurant_email) {
      message.error('Please enter restaurant email');
      return;
    }
    setLoading(true);
    if (type === 'Omni' || merchant.url) {
      const payload = {
        ...merchant,
        parent_id: parentId,
        integration: isCatering ? 'EzCater' : merchant.integration,
      };
      addNewOmniMerchant(payload).then((r) => {
        message.success('merchant created successfully!');
        if (continueAdding) {
          clearForm();
          return;
        }
        setSubmitted(true);
      }).catch((e) => {
        captureException(e);
        message.error(getHttpErrorMessage(e));
      })
        .finally(() => setLoading(false));
    } else {
      addNewMerchant({
        data: merchant,
        parent: parentId,
        is_catering: type === 'Catering',
      }).then((r) => {
        message.success('merchant created successfully!');
        if (continueAdding) {
          clearForm();
          return;
        }
        setSubmitted(true);
      }).catch((e) => {
        captureException(e);
        message.error(getHttpErrorMessage(e));
      })
        .finally(() => setLoading(false));
    }
  };

  const handleBack = () => {
    history.goBack();
  };

  const PARTNER_TYPE = [
    {
      value: 'Non-partner',
      label: 'Non-partner',
    },
    {
      value: 'Omni',
      label: 'Omni Merchant',
    },
    {
      value: 'Catering',
      label: 'Catering Merchant',
    },
  ];

  const fetchParents = (searchKey = '') => getParents(searchKey)
    .then(({ results }) => results.map((parent) => ({
      value: parent.id,
      label: parent.title,
    })))
    .catch((e) => captureException(e));

  if (submitted) {
    return <Results />;
  }

  return (
    <StyledWrapper layout="vertical" testValue>
      <div className="box box--top border-bottom-0">
        <span className="box-header">Merchant details</span>
        <AntForm.Item label="Type" className="my-2">
          <Select
            value={type}
            onChange={setType}
            options={PARTNER_TYPE}
            style={{
              width: 'fit-content',
              minWidth: isMobile ? 200 : 350,
            }}
            placeholder="Select Type"
          />
        </AntForm.Item>

        <AntForm.Item label="Parent" className="my-2">
          <DebounceSelect
            id="parent"
            allowClear
            value={parentId}
            placeholder="Select parent"
            fetchOptions={fetchParents}
            onChange={(p) => setParentId(p.value)}
            showSearch
            style={{
              width: 'fit-content',
              minWidth: isMobile ? 200 : 350,
            }}
          />
        </AntForm.Item>
      </div>

      {type === 'Non-partner' && <MainForm type={type} merchantDetail={merchant} setMerchantDetail={setMerchant} />}
      {type === 'Omni' && <OmniMerchantForm merchantDetail={merchant} setMerchantDetail={setMerchant} />}
      {type === 'Catering' && <CateringMerchantForm merchant={merchant} setMerchantDetail={setMerchant} />}

      <div className="box--bottom">
        <Flex vertical={isMobile} justify="end" gap={6} className="w-100">
          <Button onClick={handleBack} disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={() => handleSave(false)}
            loading={loading}
            type="primary"
          >
            Submit
          </Button>
          <Button
            onClick={() => handleSave(true)}
            loading={loading}
            type="primary"
          >
            Submit and add another merchant
          </Button>
        </Flex>
      </div>
    </StyledWrapper>
  );
}

export default Form;
