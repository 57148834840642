import { DeliveryIcon, PickupIcon } from 'assets/icons';
import BadgeLabel from 'components/common/BadgeLabel';
import React from 'react';

export const getOrderType = (order) => {
  if (order?.order_type === 1) {
    return (
      <BadgeLabel
        label="Pick Up"
        leadingIcon={<PickupIcon />}
        classes="type-size"
      />
    );
  }
  if (order?.order_type === 2) {
    return (
      <BadgeLabel
        label="Delivery"
        leadingIcon={<DeliveryIcon />}
        classes="type-size"
      />
    );
  }
};
