import { Radio } from 'antd';
import React, { useContext, useEffect, useMemo } from 'react';
import { modifierContext } from '../ModifierProvider';
import OptionRow from '../OptionRow';
import { getModifierMinSelections, isAutoApplied } from '../utils';

function SingleSelectModifier({ modifier, disabled }) {
  const { isSelected, handleChange, selectedModifiers } = useContext(modifierContext);
  useEffect(() => {
    if (!isAutoApplied(modifier) || modifier.options.some((option) => isSelected({ modifier, option }))) {
      return;
    }
    handleChange({ modifier, option: modifier.options[0], value: { ...modifier.options[0], qty: 1 } });
  }, [modifier, handleChange, isSelected]);

  const selectedOption = useMemo(() => {
    let selectedId = null;
    modifier.options.forEach((option) => {
      if (isSelected({ option, modifier })) {
        selectedId = option.id;
      }
    });
    return selectedId;
  }, [modifier.options, selectedModifiers]);

  return (
    <Radio.Group
      style={{ width: '100%' }}
      name={modifier.id}
      value={selectedOption}
    >
      {modifier.options.map((option) => {
        const checked = isSelected({ option, modifier });
        return (
          <Radio
            key={option.id}
            value={option.id}
            style={{ marginBottom: 12 }}
            checked={checked}
            disabled={disabled}
            className="omc-select-row pt-2"
            onClick={() => {
              if (checked && getModifierMinSelections(modifier) === 0) {
                return handleChange({
                  modifier,
                  option,
                  value: undefined,
                });
              }
              handleChange({
                modifier,
                option,
                value: {
                  ...option,
                  qty: 1,
                },
              });
            }}
          >
            <OptionRow option={option} />
          </Radio>
        );
      })}
    </Radio.Group>
  );
}

export default SingleSelectModifier;
