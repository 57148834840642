import { Colors } from 'assets/colors';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  .overview {

    &-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }

    &-title {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
      color: #1a1f36;
    }
  }

  .table {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    border-top: 1px solid #d8dce2;
  }
  @media (max-width: 768px) {
    .table {
      grid-template-columns: 1fr 1fr;
      border-left: 1px solid #d8dce2;
      grid-template-rows: 1fr;
    }
  }
  @media (max-width: 576px) {
    .table {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }
  }

  .cell {
    padding: 24px 19px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #d8dce2;
    border-bottom: 1px solid #d8dce2;
    
    @media (max-width: 1350px) {
      padding: 24px 14px;
    }
   

    &:nth-child(1),
    &:nth-child(5) {
      padding-left: 0;
    }

    &:nth-child(4),
    &:nth-child(8) {
      border-right: none;
      padding-right: 0;
    }

    @media (max-width: 768px) {
      padding: 16px 12px 8px;
      &:nth-child(1),
      &:nth-child(5) {
        padding-left: 12px;
      }
      &:nth-child(4),
      &:nth-child(8) {
        padding-right: 12px;
        border-right: 1px solid #d8dce2;
      }
    }
    &-price {
      font-weight: 600;
      font-size: 24px;
      line-height: 34px;
      color: #1f262a;
      margin: 4px 0 5px 0;
    }

    &-percentage {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #a84748;

      svg {
        margin-right: 6px;
      }

      &--growth {
        color: #21752b;
      }
    }

    &-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #4f566b;
      margin-right: 6px;

      @media (max-width: 1350px) {
        margin-right: 2px;
        white-space: nowrap;
      }
    }

    &-date {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #4f566b;
    }
  }

  .f {
    &-row {
      display: flex;
      flex-direction: row;
      align-items: center;

      &-between {
        width: 100%;
        justify-content: space-between;
      }
    }

    &-col {
      display: flex;
      flex-direction: column;
    }
  }

  .tooltip {
    display: none;
    width: 252px;
    height: fit-content !important;
    border-radius: 6px;
    background: #ffffff;
    border: 0.5px solid rgba(118, 118, 118, 0.28);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    position: absolute;
    bottom: 100%;
    opacity: 1;
    top: 32px;
    left: -90px;
    transform: none;
    padding: 14px 12px 10px;
    animation: down-to-up 0.5s;

    &-box {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        .tooltip {
          display: block;
          top: unset;
          bottom: 32px;
        }
      }
    }

    &:before {
      content: '';
      width: 10px;
      height: 10px;
      border: 0.5px solid rgba(118, 118, 118, 0.28);
      position: absolute;
      top: 100% !important;
      left: 50%;
      transform: rotate(-134deg) translateY(6px) !important;
      border-bottom: none;
      border-right: none;
      border-radius: 3px 0 0 0;
      background-color: white;
      left: 89px !important;
    }

    &-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &-current {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: ${Colors.textPrimary1};
      margin: 0 0 7px;
    }

    &-previous {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #6e7579;
    }

    &-bottom {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #4a5256;
      background: #f9f9fa;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 74px;
      left: 0;
      padding: 10px 12px;
      border-radius: 0 0 6px 6px;
    }
  }

  .coming-soon {
    background: #faf3e6;
    color: #cc8601;
    border-radius: 22px;
    padding: 4px 8px;
    width: fit-content;
    height: 26px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 4px;

    svg {
      margin-right: 6px;
    }

    p {
      margin-bottom: 0;
    }
  }
`;
