import React, { useContext } from 'react';
import {
  Breadcrumb, Divider, Form, Row, Space, Typography,
} from 'antd';
import { modifierContext } from '../ModifierProvider';
import {
  getModifierSelectionsText,
  isAutoApplied,
  isMultiSelect,
} from '../utils';
import MultiSelectModifier from './MultiSelectModifier';
import SingleSelectModifier from './SingleSelectModifier';

function Modifier({ modifier }) {
  const { hasSelectionsSatisfied, parent, handleBack } = useContext(modifierContext);
  const isSatisfied = hasSelectionsSatisfied(modifier);

  const Control = isMultiSelect(modifier)
    ? MultiSelectModifier
    : SingleSelectModifier;
  const disabled = isAutoApplied(modifier);
  return (
    <Form>
      <Space className="900" direction="vertical" style={{ width: '100%' }}>
        {parent && (
          <Breadcrumb items={[
            {
              title: <a onClick={handleBack}>{parent.modifier.title}</a>,
            },
            {
              title: parent.option.title,
            },
          ]}
          />
        )}
        <div className="flex justify-content-between">
          <Typography.Text strong type={isSatisfied ? 'primary' : 'danger'}>{modifier.title}</Typography.Text>
          {!isSatisfied && !isAutoApplied(modifier) && (
            <Typography.Text type="danger" mr={3}>
              Required
            </Typography.Text>
          )}
        </div>
        <Row>
          {!isAutoApplied(modifier) && (
            <Typography.Text type="secondary">
              {getModifierSelectionsText(modifier)}
            </Typography.Text>
          )}
        </Row>

        <Control modifier={modifier} disabled={disabled} />
        <Divider orientationMargin={15} />
      </Space>
    </Form>
  );
}

export default Modifier;
