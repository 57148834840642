import { useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { Modal } from 'antd';
import StripeForm from './StripeForm';

function StripePaymentModal({
  open, onClose, reloadCards, title = 'Add credit card', subtitle, onBeforeSubmit,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    if (onBeforeSubmit) {
      await onBeforeSubmit();
    }
    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    });

    if (error) {
      setLoading(false);
    } else {
      reloadCards();
      onClose();
    }
  };
  return (
    <Modal
      open={open}
      title={title}
      onCancel={onClose}
      footer={[]}
    >
      {open && (
        <StripeForm onSubmit={onSubmit} loading={loading} onClose={onClose} title={subtitle} />
      )}
    </Modal>
  );
}

export default StripePaymentModal;
