import React from 'react';

function KosherIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.15" x="1.15771" y="1.31982" width="13.3333" height="13.3333" rx="4" fill="black" fill-opacity="0.45"/>
      <path d="M5.27374 11.2992V4.70117H6.52004V7.74361L9.03097 4.70117H10.5522L7.79383 7.95438L10.7263 11.2992H9.1501L6.52004 8.29345V11.2992H5.27374Z" fill="black" fill-opacity="0.45"/>
    </svg>
  );
}
export default KosherIcon;
