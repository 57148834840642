import { Space, Tabs } from 'antd';
import Layout from 'components/Layout';
import { captureException } from 'utils/errors';
import StripeContainer from 'containers/AccountSettings/Subscription/StripeContainer';
import React, { useEffect, useState } from 'react';
import {
  fetchOrganizationPaymentMethods,
} from './requests';
import BillingHistory from './BillingHistory';
import PaymentMethods from './PaymentMethods';

function Subscription() {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [needRefreshIntent, setNeedRefreshIntent] = useState(false);
  const getPaymentMethods = (refreshIntent) => {
    setNeedRefreshIntent(refreshIntent);
    setLoading(true);
    fetchOrganizationPaymentMethods().then((res) => {
      setCards(res.data);
    }).catch((e) => captureException(e))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getPaymentMethods();
  }, []);

  const items = [
    {
      key: '1',
      label: 'Payment Method',
      children: <PaymentMethods cards={cards} reFetch={getPaymentMethods} loading={loading} />,
    },
    {
      key: '2',
      label: 'Billing History',
      children: <BillingHistory />,
    },
  ];
  return (
    <Layout header={(
      <Layout.Header
        title="Subscription"
        backTitle="Account settings"
        backPath="/console/setting"
      />
    )}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <StripeContainer needRefresh={needRefreshIntent}>
          <Tabs defaultActiveKey="1" items={items} />
        </StripeContainer>
      </Space>
    </Layout>
  );
}

export default Subscription;
