import React from 'react';

function HalalIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.15" x="1.15771" y="1.31982" width="13.3333" height="13.3333" rx="4" fill="black" fill-opacity="0.45"/>
      <path d="M5.27832 11.2992V4.70117H6.52462V7.41371H9.47543V4.70117H10.7217V11.2992H9.47543V8.57754H6.52462V11.2992H5.27832Z" fill="black" fill-opacity="0.45"/>
    </svg>
  );
}
export default HalalIcon;
