import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  App,
  Button, Divider, Drawer, Flex, Input, Skeleton, Typography,
} from 'antd';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import http from 'services/http';
import AmountInput from 'components/AmountInput';
import ItemTypes from './ItemTypes';
import ModifierProvider, { modifierContext } from './ModifierProvider';
import Modifiers from './Modifiers';
import { ItemsInputContext } from '../constants';
import QuantityInput from '../../../../../components/QuantityInput';

const { TextArea } = Input;

function ItemDetailOverlay({
  onClose,
  onChange,
  nested,
  onSubmit,
}) {
  const { selectedOrderItem } = useContext(ItemsInputContext);
  const [item, setItem] = useState(null);
  const selectedOptions = useMemo(() => selectedOrderItem?.order_options?.map((op) => ({
    id: op.modifier_option.id,
    qty: op.qty,
  }) ?? []), [selectedOrderItem]);

  useEffect(() => {
    if (selectedOrderItem && selectedOrderItem?.item_related?.id !== item?.id) {
      setItem(null);
      http.get(`v1/items/${selectedOrderItem.item_related.id}/`).then(({ data: { data } }) => {
        setItem({
          ...data,
          real_price: Number(data.real_price),
        });
      });
    }
  }, [selectedOrderItem, item]);

  return (
    <ModifierProvider modifiers={item?.modifiers} selectedOptions={selectedOptions}>
      <Overlay
        open={Boolean(selectedOrderItem)}
        onChange={onChange}
        onClose={onClose}
        orderItem={selectedOrderItem}
        item={item}
        nested={nested}
        onSubmit={onSubmit}
      />
    </ModifierProvider>
  );
}

function Overlay({
  open,
  orderItem,
  item,
  onChange,
  onClose,
  onSubmit,
  nested,
}) {
  const [editingOrderItem, setEditingOrderItem] = useState({});
  useEffect(() => {
    setEditingOrderItem({ ...orderItem });
  }, [orderItem]);

  const {
    getNormalizedSelectedModifiers,
    unSatisfiedModifiers,
    handleBack,
    parent,
    loading,
  } = useContext(modifierContext);

  const { message } = App.useApp();

  const hasNoModifierOrItemType = () => {
    if (!orderItem) {
      return true;
    }
    return (
      orderItem.modifiers?.length === 0 &&
      orderItem.item_types?.filter(
        (type) => type.price != null && type.size_id && type.type,
      ).length <= 1
    );
  };

  const handleAddToCart = () => {
    const newOrderItem = {
      ...editingOrderItem,
      order_options: getNormalizedSelectedModifiers().map((o) => ({
        modifier_option: o,
        real_price: o.price, // TODO: CHECK This
        qty: o.qty,
      })),
    };
    onChange(newOrderItem);
    onClose();
  };

  function handleContinue() {
    if (unSatisfiedModifiers.length) {
      message.open({
        type: 'error',
        content: 'Please select required modifiers!',
      });
      return;
    }
    if (parent) {
      handleBack();
      return;
    }
    handleAddToCart();
    onSubmit();
    onClose();
  }

  const getCartButtonLabel = () => {
    if (parent || unSatisfiedModifiers.length > 0) {
      return 'Continue';
    }

    if (editingOrderItem?.isNew) {
      return 'Add to Cart';
    }
    return 'Update';
  };

  function handleChange(payload) {
    setEditingOrderItem((c) => ({ ...c, ...payload }));
  }
  console.log({ editingOrderItem });

  return (
    <Drawer
      title={orderItem?.item_related?.name || item?.name}
      placement="right"
      onClose={() => {
        onClose();
      }}
      width={nested ? 600 : 700}
      open={open}
      size="large"
      zIndex={2000}
      footer={(
        <Flex gap={8}>
          <Button
            key="back"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            key="Add to cart"
            type="primary"
            onClick={handleContinue}
          >
            {getCartButtonLabel()}
          </Button>
        </Flex>
      )}
    >
      {renderContent()}
    </Drawer>
  );

  function renderContent() {
    if (!item) {
      return <Skeleton paragraph={{ rows: 5 }} />;
    }
    return (
      <>
        {parent && (
          <Button type="link" size="small" onClick={handleBack} style={{ marginBottom: 6, marginLeft: -8 }}>
            <ArrowLeftOutlined />
            Back
          </Button>
        )}
        <Flex justify="space-between">
          <Flex vertical gap={8}>
            <Typography.Text strong>Item price</Typography.Text>
            <AmountInput
              value={editingOrderItem.real_price || null}
              onChange={(real_price) => handleChange({ real_price })}
            />
          </Flex>
          <Flex vertical gap={8}>
            <Typography.Text strong>Quantity</Typography.Text>
            <QuantityInput
              value={editingOrderItem.qty}
              onChange={(qty) => handleChange({ qty })}
            />
          </Flex>

        </Flex>

        <Divider />

        {!orderItem ? (
          <Skeleton paragraph={{ rows: 5 }} />
        ) : (
          <>
            {!hasNoModifierOrItemType() && (
              <>
                {
                  !parent && (
                    <ItemTypes
                      item={item}
                      value={editingOrderItem?.size}
                      onChange={(size) => {
                        handleChange({ size });
                      }}
                    />
                  )
                }
                <Modifiers />
              </>
            )}
            {
              !parent && (
                <>
                  <Typography.Text>Special notes</Typography.Text>
                  <TextArea
                    style={{ marginTop: 10 }}
                    value={editingOrderItem.message}
                    onChange={(e) => {
                      handleChange({ message: e.target.value });
                    }}
                    rows={4}
                    placeholder="Special notes"
                    maxLength={100}
                  />
                </>
              )
            }

          </>
        )}
      </>
    );
  }
}

export default ItemDetailOverlay;
