import { PlusOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import { fetchMerchantsList } from 'api/merchants';
import Layout from 'components/Layout';
import { AdminContext, NewOrdersContext } from 'constants/contexts';
import React, {
  useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { captureException } from 'utils/errors';
import { PERMISSIONS } from 'constants/permissions';
import withGuard from 'components/withGuard';
import { useHasAnyOfPermissions } from 'hooks/useHasPermission';
import { TABS } from './constants';
import Table from './Table';

function Merchants() {
  const [search, setSearch] = useState('');
  const resetFetchRef = useRef(false);
  const [activeTab, setActiveTab] = useState(TABS[0]);
  const [dataVersion, setDataVersion] = useState(1);
  const isAdmin = useHasAnyOfPermissions([PERMISSIONS.ADMIN_ACCESS]);
  const { organizationId } = useContext(AdminContext);

  const history = useHistory();
  const handleRefetch = useCallback(() => setDataVersion((v) => v + 1), []);

  const handleResetFetch = useCallback(() => {
    resetFetchRef.current = true;
  }, []);
  const { newOrderIds } = useContext(NewOrdersContext);

  useEffect(() => {
    handleRefetch();
  }, [handleRefetch, newOrderIds]);

  const fetchMerchants = useCallback(
    (pagination, filter, sorter) => {
      const page = resetFetchRef.current ? 1 : pagination.current;
      const queries = {
        search,
        page,
        page_size: pagination.pageSize,
        is_disabled: 'false',
      };
      if (sorter?.order && sorter?.columnKey) {
        queries.ordering = `${sorter?.order === 'descend' ? '-' : ''}${sorter?.columnKey}`;
      }
      if (activeTab?.id !== TABS[0]?.id) {
        queries[activeTab.param.title] = activeTab.param.value?.toString();
      }

      resetFetchRef.current = false;

      return fetchMerchantsList(queries)
        .then(({ data: { data: { results, count } } }) => ({
          data: results,
          total: count,
          page,
        }))
        .catch(captureException);
    },
    [
      search,
      activeTab,
      dataVersion,
      organizationId,
    ],
  );

  const handleSearchChange = (e) => {
    handleResetFetch();
    setSearch(e.target.value);
  };

  const handleChangeTab = (value) => {
    handleResetFetch();
    const currentTab = TABS[value - 1];
    setActiveTab(currentTab);
  };

  const handleCreateMerchants = () => {
    history.push('/console/merchants/new/create');
  };

  return (
    <Layout header={(
      <Layout.Header
        title="Merchants"
        onSearch={handleSearchChange}
        searchPlaceholder="Search merchants..."
        extra={[
          isAdmin && (
            <Button
              className="flex-row"
              type="default"
              size="middle"
              onClick={handleCreateMerchants}
              icon={<PlusOutlined />}
            >
              Create new
            </Button>
          ),
        ].filter(Boolean)}
      />
    )}
    >
      {
        isAdmin && (
          <Tabs items={TABS} defaultActiveKey={TABS[0].key} onChange={handleChangeTab} />
        )
      }
      <Table
        fetchMerchants={fetchMerchants}
        refetch={handleRefetch}

      />
    </Layout>
  );
}

export default withGuard(Merchants, [PERMISSIONS.MERCHANTS], { redirect: true });
