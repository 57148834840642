import {
  Col, Divider, Flex, Input, Row, Space, Switch, Typography,
} from 'antd';
import Tags from '../../../../components/DietaryTags/TagSelector';
import { ModifierContext } from 'containers/MenuManagement/context';
import React, { useContext } from 'react';
import CateringUnit from './CateringUnit';
import Modifiers from './Modifiers';
import { MenuContext } from '../../../context';
import AdditionalTax from './AdditionalTax';
import { getItemImages } from '../utils';
import Option from './Option';
import UploadItemImage from './UploadItemImage';

function ItemForm({ detail, setDetail }) {
  const { showMessage } = useContext(MenuContext);
  const { merchant } = useContext(ModifierContext);
  const onChangeDetail = (key, value) => {
    setDetail({ ...detail, [key]: value });
  };

  const onChangeOption = ({ index, option }) => {
    const options = [...detail.options];
    options[index] = option;
    onChangeDetail('options', options);
  };
  const onAddOption = () => {
    if (detail.options.length < 4) {
      setDetail({ ...detail, options: [...detail.options, { type: '', price: 0 }] });
    } else {
      showMessage({ type: 'error', content: 'You can define just 4 options!' });
    }
  };
  const onRemoveOption = (index) => {
    const options = [...detail.options];
    options.splice(index, 1);
    onChangeDetail('options', options);
  };

  return (
    <Flex vertical>
      <Flex vertical gap={16}>
        <Flex vertical gap={8}>
          <Typography.Text strong>Name</Typography.Text>
          <Input placeholder="Item name" value={detail.name} onChange={(e) => onChangeDetail('name', e.target.value)} />
        </Flex>

        <Space style={{ background: '#E6F3EB', borderRadius: 5, padding: 16 }} direction="vertical">
          <Flex justify="space-between" align="center">
            <Typography.Text strong>Mark as available</Typography.Text>
            <Switch
              defaultChecked
              checked={detail.isActive}
              onChange={(value) => onChangeDetail('isActive', value)}
              style={{ marginRight: 8 }}
            />
          </Flex>
          <Typography.Text>
            Customers can view item and select it.
          </Typography.Text>
        </Space>

        {merchant.is_catering && (
          <>
            <Divider />
            <CateringUnit detail={detail} onChangeDetail={onChangeDetail} />
          </>
        )}
        <Divider />
        <Flex vertical gap={8}>
          <Row gutter={8} align="middle">
            <Col span={7}><Typography.Text strong>Price</Typography.Text></Col>
            <Col span={13}><Typography.Text strong>Option</Typography.Text></Col>
          </Row>
          {detail?.options?.map((option, index) => (
            <Option
              key={index}
              data={option}
              index={index}
              changeOption={onChangeOption}
              addOption={onAddOption}
              removeOption={onRemoveOption}
            />
          ))}
        </Flex>

        <AdditionalTax detail={detail} onChange={onChangeDetail} />
        <Divider />
        <Row gutter={[8, 16]} align="middle">
          <Col xs={24} md={18}>
            <Flex vertical gap={8}>
              <Typography.Text strong>
                Description
                <Typography.Text type="secondary"> (optional)</Typography.Text>
              </Typography.Text>
              <Input.TextArea
                value={detail.description}
                rows={3}
                onChange={(e) => onChangeDetail('description', e.target.value)}
              />
            </Flex>
          </Col>
          <Col xs={24} md={6}>
            <UploadItemImage image={getItemImages(detail.image)} onChange={onChangeDetail} />
          </Col>
          <Col span={24}>
            <Tags detail={detail} onChangeDetail={onChangeDetail} />
          </Col>
        </Row>

      </Flex>
      <Divider />

      <Modifiers modifiers={detail.modifiers} onChangeModifiers={(modifiers) => onChangeDetail('modifiers', modifiers)} />

    </Flex>
  );
}

export default ItemForm;
