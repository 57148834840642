import React from 'react';
import { Input } from 'antd';

function CentInput({ value, onChange, ...props }) {
  function handleChange({ target: { value: v } }) {
    onChange(v * 100);
  }
  return <Input prefix="$" type="number" {...props} onChange={handleChange} value={(value || 0) / 100} />;
}

export default CentInput;
