import { OrderStatus } from 'constants/status';

export const getPaymentType = (type) => {
  switch (type) {
  case 0:
    return 'CREDIT/DEBIT';
  case 1:
    return 'CASH';
  case 2:
    return 'ALTERNATE PAYMENT';
  case 3:
    return 'DEBIT';
  case 4:
    return 'CUSTOM';
  default:
    return '';
  }
};

export function isOrderStatus(status) {
  return Object.values(OrderStatus).includes(status);
}
